import {
  SIGN_IN,
  SIGN_OUT,
  FORCE_SIGN_OUT,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  REGISTER,
  SEND_PASSWORD_RESET_CODE,
  SEND_PASSWORD_RESET_CODE_SUCCESS,
  SEND_PASSWORD_RESET_CODE_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD
} from "./types";
import { createFormAction } from "redux-form-saga";

export const signIn = createFormAction(SIGN_IN);

export const signOut = () => {
  return { type: SIGN_OUT };
};

export const forceSignOut = () => {
  return { type: FORCE_SIGN_OUT };
};

export const signOutSuccess = () => {
  return { type: SIGN_OUT_SUCCESS };
};

export const signOutFailure = () => {
  return { type: SIGN_OUT_FAILURE };
};

export const register = createFormAction(REGISTER);

export const changePassword = createFormAction(CHANGE_PASSWORD);

export const sendPasswordResetCode = email => {
  return { type: SEND_PASSWORD_RESET_CODE, payload: { email } };
};

export const sendPasswordResetCodeSuccess = () => {
  return { type: SEND_PASSWORD_RESET_CODE_SUCCESS, payload: {} };
};

export const sendPasswordResetCodeFailure = () => {
  return { type: SEND_PASSWORD_RESET_CODE_FAILURE, payload: {} };
};

export const resetPassword = ({ email, resetCode, newPassword }) => {
  return { type: RESET_PASSWORD, payload: { email, resetCode, newPassword } };
};

export const resetPasswordSuccess = () => {
  return { type: RESET_PASSWORD_SUCCESS, payload: {} };
};

export const resetPasswordFailure = () => {
  return { type: RESET_PASSWORD_FAILURE, payload: {} };
};
