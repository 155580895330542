import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "Inter",
    h2: {
      fontFamily: "DM Sans",
      fontSize: "24px",
      fontWeight: "400",
      lineHeight: "32px",
      color: "#2B2A2E",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "initial",
          backgroundColor: "transparent",
          "&:hover": { backgroundColor: "transparent" },
          color: "#5E54FF",
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": { backgroundColor: "transparent" },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: "1px solid #C3C2C9",
          borderRadius: "7px",
          width: "828px",
          boxShadow: "none",
          "&:before": {
            content: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "32px",
          height: "92px",
          "&:hover": {
            backgroundColor: " #F4F4F5",
            borderRadius: "7px",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "32px",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          border: "none",
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "#F3F4FF",
            borderBottom: "1px solid #C8CEFF",
          },
          "&:before": {
            content: "none",
          },
          "&.Mui-focused": {
            borderBottom: "none",
            backgroundColor: "#F3F4FF",
          },
        },
      },
    },
  },
});
