import {
  ADD_SELECTED_ARTWORK,
  REMOVE_SELECTED_ARTWORK,
  REPLACE_SELECTED_ARTWORK,
  CHANGE_SELECTED_COLLECTION,
  CHANGE_SELECTED_CATEGORY,
  SELECT_ALL_ARTWORKS,
  CLEAR_ALL_SELECTED_ARTWORKS,
  ADD_SELECTED_CONTACT,
  REMOVE_SELECTED_CONTACT,
  REPLACE_SELECTED_CONTACT,
  SELECT_ALL_CONTACTS,
  CLEAR_ALL_SELECTED_CONTACTS,
} from "./types";

export const addSelectedArtwork = (artworkId) => {
  return { type: ADD_SELECTED_ARTWORK, payload: { artworkId } };
};

export const removeSelectedArtwork = (artworkId) => {
  return { type: REMOVE_SELECTED_ARTWORK, payload: { artworkId } };
};

export const replaceSelectedArtwork = (artworkId) => {
  return { type: REPLACE_SELECTED_ARTWORK, payload: { artworkId } };
};

export const changeSelectedCollection = (collectionId) => {
  return { type: CHANGE_SELECTED_COLLECTION, payload: { collectionId } };
};

export const changeSelectedCategory = (category) => {
  return { type: CHANGE_SELECTED_CATEGORY, payload: { category } };
};

export const selectAllArtworks = (artworkIds) => {
  return { type: SELECT_ALL_ARTWORKS, payload: { artworkIds } };
};

export const clearAllSelectedArtworks = () => {
  return { type: CLEAR_ALL_SELECTED_ARTWORKS, payload: {} };
};

export const addSelectedContact = (contactId) => {
  return { type: ADD_SELECTED_CONTACT, payload: { contactId } };
};

export const removeSelectedContact = (contactId) => {
  return { type: REMOVE_SELECTED_CONTACT, payload: { contactId } };
};

export const replaceSelectedContact = (contactId) => {
  return { type: REPLACE_SELECTED_CONTACT, payload: { contactId } };
};

export const selectAllContacts = (contactIds) => {
  return { type: SELECT_ALL_CONTACTS, payload: { contactIds } };
};

export const clearAllSelectedContacts = () => {
  return { type: CLEAR_ALL_SELECTED_CONTACTS, payload: {} };
};
