export function isOverlapping(comp1, comp2) {
  const comp1BBox = comp1.current && comp1.current.getBoundingClientRect();
  const comp2BBox = comp2.current && comp2.current.getBoundingClientRect();
  return !(
    comp1BBox.right < comp2BBox.left ||
    comp1BBox.left > comp2BBox.right ||
    comp1BBox.bottom < comp2BBox.top ||
    comp1BBox.top > comp2BBox.bottom
  );
}
