import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectUser } from "../../store/selectors";

const WithRequireAccess = (WrappedComponent, minAccessLevel = 0) => {
  const WithRequireAccessComponent = (props) => {
    const history = useHistory();
    const user = useSelector(selectUser);

    useEffect(() => {
      if (user.account.accessLevel < minAccessLevel && user.account.activePlan === "none") {
        history.replace("/pay");
      } else if (user.account.accessLevel === 1 && user.account.paymentStatus === "PAST_DUE") {
        history.replace("/expired-payment-method");
      }
    }, [user]);

    return <WrappedComponent {...props} />;
  };

  return WithRequireAccessComponent;
};

export { WithRequireAccess };
