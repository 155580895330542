import React from "react";
import styled from "styled-components";
import { flexbox, space, color, typography, layout, border, position, shadow, background } from "styled-system";

const StyledBox = styled.div`
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${border}
  ${shadow}
  ${flexbox}
  ${position}
  ${background}
  ${props => props.cursor && `cursor: ${props.cursor};`}
  ${props => props.whiteSpace && `white-space: ${props.whiteSpace};`}
  ${props => props.pointerEvents && `pointer-events: ${props.pointerEvents};`}
  ${props =>
    props.userSelect &&
    `
      user-select: ${props.userSelect};
      -moz-user-select: ${props.userSelect};
    `}
  ${props => props.scrollBehavior && `scroll-behavior: ${props.scrollBehavior};`}
`;

const Box = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledBox ref={ref} {...props}>
      {children}
    </StyledBox>
  );
});

export { Box };
