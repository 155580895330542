import {
  CACHE_IMAGE,
  RECACHE_IMAGE,
  REMOVE_CACHE_IMAGE,
  CACHE_IMAGE_ACTIVE,
  CLEAR_UNACTIVE_CACHE_IMAGES
} from "./types";

export const cacheImage = (localId, fileAsDataUrl, url) => {
  return { type: CACHE_IMAGE, payload: { localId, fileAsDataUrl, url } };
};

export const recacheImage = (localId, url) => {
  return { type: RECACHE_IMAGE, payload: { localId, url } };
};

export const removeCacheImage = localId => {
  return { type: REMOVE_CACHE_IMAGE, payload: { localId } };
};

export const cacheImageActive = localId => {
  return { type: CACHE_IMAGE_ACTIVE, payload: { localId } };
};

export const clearUnactiveCacheImages = () => {
  return { type: CLEAR_UNACTIVE_CACHE_IMAGES };
};
