import { v4 as uuid } from "uuid";
import {
  INITIALISE_QUEUE_SYSTEM,
  ADD_ACTION_TO_QUEUE,
  REMOVE_ACTION_FROM_QUEUE,
  ADD_ACTION_TO_DELAY_QUEUE,
  REMOVE_ACTION_FROM_DELAY_QUEUE,
  EXECUTE_ACTION_IN_QUEUE,
  EXECUTE_ACTION_IN_QUEUE_SUCCESS,
  EXECUTE_ACTION_IN_QUEUE_FAILURE,
  EXECUTE_ACTION_IN_QUEUE_FINISHED,
  ADD_FAILED_ACTIONS_TO_QUEUE,
  REMOVE_FAILED_ACTION_FROM_QUEUE,
  INITIALISE_QUEUE_SYSTEM_CLEANER,
  START_QUEUE_SYSTEM_CLEANER,
  STOP_QUEUE_SYSTEM_CLEANER,
  QUEUE_CLEANED_SUCCESS,
  QUEUE_CLEANED_FAILURE,
} from "./types";

export const initialiseQueueSystem = () => {
  return { type: INITIALISE_QUEUE_SYSTEM };
};

export const addActionToQueue = (action, itemId, id = uuid(), attempt = 0) => {
  return {
    type: ADD_ACTION_TO_QUEUE,
    payload: { id, itemId, attempt, action },
  };
};

export const removeActionFromQueue = (actionId) => {
  return { type: REMOVE_ACTION_FROM_QUEUE, payload: { actionId } };
};

export const addActionToDelayQueue = (actionId) => {
  return { type: ADD_ACTION_TO_DELAY_QUEUE, payload: { actionId } };
};
export const removeActionFromDelayQueue = (actionId) => {
  return { type: REMOVE_ACTION_FROM_DELAY_QUEUE, payload: { actionId } };
};

export const executeActionInQueue = (actionId) => {
  return { type: EXECUTE_ACTION_IN_QUEUE, payload: { actionId } };
};

export const executeActionInQueueSuccess = (actionId) => {
  return { type: EXECUTE_ACTION_IN_QUEUE_SUCCESS, payload: { actionId } };
};

export const executeActionInQueueFailure = (actionId) => {
  return { type: EXECUTE_ACTION_IN_QUEUE_FAILURE, payload: { actionId } };
};

export const executeActionInQueueFinished = (actionId) => {
  return { type: EXECUTE_ACTION_IN_QUEUE_FINISHED, payload: { actionId } };
};

export const addFailedActionsToQueue = () => {
  return { type: ADD_FAILED_ACTIONS_TO_QUEUE };
};

export const removeFailedActionFromQueue = (actionId) => {
  return { type: REMOVE_FAILED_ACTION_FROM_QUEUE, payload: { actionId } };
};

export const initialiseQueueSystemCleaner = () => {
  return { type: INITIALISE_QUEUE_SYSTEM_CLEANER };
};

export const startQueueSystemCleaner = () => {
  return { type: START_QUEUE_SYSTEM_CLEANER };
};

export const stopQueueSystemCleaner = () => {
  return { type: STOP_QUEUE_SYSTEM_CLEANER };
};

export const queueCleanedSuccess = () => {
  return { type: QUEUE_CLEANED_SUCCESS };
};

export const queueCleanedFailure = () => {
  return { type: QUEUE_CLEANED_FAILURE };
};
