import { CHANGE_CONNECTION_STATUS, UPDATE_APP_VERSION, RESET_APP, RESET_APP_SUCCESS, RESET_APP_FAILURE } from "./types";

export const changeConnectionStatus = connectionState => {
  return { type: CHANGE_CONNECTION_STATUS, payload: { connectionState } };
};

export const updateAppVersion = version => {
  return { type: UPDATE_APP_VERSION, payload: { version } };
};

export const resetApp = () => {
  return { type: RESET_APP };
};

export const resetAppSuccess = () => {
  return { type: RESET_APP_SUCCESS };
};

export const resetAppFailure = () => {
  return { type: RESET_APP_FAILURE };
};
