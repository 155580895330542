import React from "react";
import styled from "styled-components";
import { space, border, color, variant, layout, flexbox, typography, position } from "styled-system";

import { Box, Flex, Text, Icon } from "../fundamentals";

const StyledButton = styled("button")`
  position: relative;
  display: inline-block;
  border: 1px solid;
  user-select: none;
  outline: none;
  white-space: nowrap;
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  align-self: start;
  pointer-events: all;
  ${(props) =>
    props.accessibilityMode &&
    `
      &:focus {
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: -8px;
          bottom: -8px;
          left: -8px;
          right: -8px;
          border-radius: 22px;
          border: 4px solid #76b5ff;
        }
      }
  `}
  ${(props) =>
    !props.inverted &&
    variant({
      prop: "buttonStyle",
      variants: {
        primary: {
          bg: "blue.60",
          borderColor: "blue.60",
          borderRadius: "17px",
          color: "white",
          boxShadow: "small",
          "&:hover": {
            bg: "blue.50",
            borderColor: "blue.50",
          },
          "&:active": !props.disabled && {
            bg: "blue.70",
            boxShadow: "none",
          },
        },
        secondary: {
          bg: "transparent",
          color: "blue.60",
          borderColor: "blue.60",
          borderRadius: "17px",
          "&:hover": { bg: "blue.50", color: "white", borderColor: "blue.50" },
          "&:active": !props.disabled && {
            bg: "blue.70",
            boxShadow: "none",
            color: "white",
          },
        },
        default: {
          borderRadius: "20px",
          border: "3px solid",
          borderColor: !props.disabled ? "black" : "#DEE1FF",
          color: props.disabled && "#DEE1FF",
          fontWeight: "600",
          "&:hover": !props.disabled && {
            color: "white",
            bg: "black",
          },
        },
        text: {
          color: props.color ? props.color : "blue.60",
          border: "none",
          boxShadow: "none",
          bg: "transparent",
          textDecoration: props.textDecoration ?? "none",
          "&:hover": { color: props.hoverColor || "blue.50" },
          "&:active": !props.disabled && {
            color: "blue.70",
          },
        },
        link: {
          color: props.color ? props.color : "black",
          border: "none",
          boxShadow: "none",
          bg: "transparent",
          textDecoration: props.textDecoration ? props.textDecoration : "underline",
        },
      },
    })(props)}
  ${(props) =>
    props.inverted &&
    variant({
      prop: "buttonStyle",
      variants: {
        primary: {
          bg: "white",
          borderColor: "white",
          borderRadius: "17px",
          color: "blue.60",
          boxShadow: "small",
          "&:hover": {
            bg: "blue.10",
            borderColor: "blue.10",
            color: "blue.50",
          },
          "&:active": !props.disabled && {
            bg: "blue.10",
            color: "blue.70",
            boxShadow: "none",
          },
        },
        secondary: {
          bg: "blue.50",
          color: "white",
          borderColor: "blue.50",
          borderRadius: "17px",
          "&:hover": { bg: "white", color: "blue.60", borderColor: "white" },
          "&:active": !props.disabled && {
            bg: "blue.10",
            boxShadow: "none",
            color: "blue.60",
          },
        },
        text: {
          color: "white",
          border: "none",
          boxShadow: "none",
          bg: "transparent",
          "&:hover": { color: props.hoverColor || "blue.20" },
          "&:active": !props.disabled && {
            color: "blue.30",
          },
        },
      },
    })}
  ${(props) =>
    props.disabled &&
    variant({
      prop: "buttonStyle",
      variants: {
        primary: {
          pointerEvents: "none",
          bg: props.inverted ? "blue.30" : "blue.40",
          borderColor: props.inverted ? "white" : "blue.40",
        },
        secondary: {
          pointerEvents: "none",
          color: props.inverted ? "blue.30" : "blue.40",
          borderColor: props.inverted ? "transparent" : "blue.40",
        },
        text: {
          pointerEvents: "none",
          color: "blue.40",
        },
      },
    })(props)}


  ${(props) =>
    variant({
      prop: "size",
      variants: {
        large: {
          fontSize: "heading2",
          lineHeight: "heading2",
          padding: "xs",
          paddingLeft: "l",
          paddingRight: "l",
          borderRadius: "29px",
        },
        medium: {
          fontSize: "body",
          lineHeight: "button",
          padding: "xxs",
          paddingLeft: !props.iconOnly && "m",
          paddingRight: !props.iconOnly && "m",
        },
        small: {
          fontSize: "small",
          lineHeight: "button",
          padding: "xxxs",
          paddingLeft: !props.iconOnly && "s",
          paddingRight: !props.iconOnly && "s",
        },
      },
    })(props)};
  ${position}
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${typography}
  ${border}
  ${(props) => props.transition && `transition: ${props.transition};`}
`;

const StyledIcon = styled(Icon)`
  ${(props) => `height: ${props.theme.lineHeights.button}; width: ${props.theme.lineHeights.button};`}
  & > svg {
    ${variant({
      prop: "size",
      variants: {
        small: ({ lineHeights: { button } }) => {
          return { height: button, width: button };
        },
        medium: ({ lineHeights: { button } }) => {
          return { height: `calc(${button} + 2px)`, width: `calc(${button} + 2px)` };
        },
        large: ({ lineHeights: { button } }) => {
          return { height: `calc(${button} + 8px)`, width: `calc(${button} + 8px)` };
        },
      },
    })}
  }
`;

export const Button = React.forwardRef(
  (
    {
      buttonStyle,
      size,
      children,
      icon,
      iconSpinning,
      iconOffset,
      iconSize,
      iconStrokeWidth,
      disabled,
      badge,
      flip,
      label,
      justifyText,
      loading,
      iconCursor,
      onClick,
      preventDefault,
      ...props
    },
    ref
  ) => {
    return (
      <StyledButton
        ref={ref}
        buttonStyle={buttonStyle}
        size={size}
        disabled={disabled}
        fontFamily="bodyDmSans"
        iconOnly={!children}
        onClick={(e) => {
          preventDefault && e.preventDefault();
          onClick && onClick(e);
        }}
        {...props}
      >
        <Flex
          justifyContent={justifyText ? justifyText : buttonStyle === "text" ? "flex-start" : "center"}
          position="relative"
        >
          {!flip && (
            <StyledIcon
              icon={icon}
              cursor={iconCursor ?? "default"}
              spin={iconSpinning}
              buttonStyle={buttonStyle}
              size={iconSize || size}
              mr={(!children && !label) || iconOffset || "xxs"}
              disabled={disabled}
              strokeWidth={iconStrokeWidth}
            />
          )}
          <Text>{label || children}</Text>
          {flip && (
            <StyledIcon
              icon={icon}
              spin={iconSpinning}
              buttonStyle={buttonStyle}
              size={iconSize || size}
              ml={(!children && !label) || iconOffset || "xxs"}
              disabled={disabled}
              strokeWidth={iconStrokeWidth}
            />
          )}
          {loading && <StyledIcon icon="Loader" spin={true} size={size} ml="xs" />}
        </Flex>
        {badge && (
          <Box height="10px" width="10px" borderRadius="50%" position="absolute" bg="blue.60" right="0" top="0" />
        )}
      </StyledButton>
    );
  }
);
