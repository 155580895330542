export * from "./apis";
export * from "./options";
export * from "./joyride";
export * from "./onboarding";
export const maxOfflineRetries = 3;

export const WEBAPP_VERSION = "2.0.1";

export const SW_CONFIG = {
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
  onSuccess: (registration) => {},
};
