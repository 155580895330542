import _ from "lodash";
import { createSelector } from "reselect";
import { selectSelectedCollectionId, selectSelectedCategory, selectSelectedArtworkIds } from "./selectionSelectors";
import { selectSearch } from "./searchSelectors";
import { selectUser } from "./userSelectors";
import { artworkContainsSearchTags } from "../../utils";
import { UiLabels } from "../../localisation";
import { DEFAULT_ARTWORK_STATUSES } from "../../config";

export const selectCollections = (state) => state.archive.collections.byId;

export const selectArtworks = (state) => state.archive.artworks.byId;

export const selectArtists = (state) => state.archive.artists.byId;

export const selectShareRecords = (state) => state.archive.shareRecords.byId;

export const selectArtworkId = (state, artworkId) => artworkId;
export const selectArtworkIds = (state, artworkIds) => artworkIds;
export const selectArtistId = (state, artistId) => artistId;
export const selectCategory = (state, category) => category;
export const selectShareArtworkIds = (state, shareArtworkIds) => shareArtworkIds;
export const selectShareRecordListType = (state, shareRecordListType) => shareRecordListType;

export const selectArtworkById = createSelector(selectArtworks, selectArtworkId, (artworks, artworkId) => {
  return artworks[artworkId];
});

export const selectArtworksById = createSelector(selectArtworks, selectArtworkIds, (artworks, artworkIds) => {
  const selectedArtworks = _.map(artworkIds, (artworkId) => artworks[artworkId]);

  return selectedArtworks;
});

export const selectArtistById = createSelector(selectArtists, selectArtistId, (artists, artistId) => {
  return artists[artistId];
});

export const selectArtworksByShareArtworkIds = createSelector(
  selectArtworks,
  selectShareArtworkIds,
  (artworks, shareArtworkIds) => {
    const sharedArtworks = _(shareArtworkIds)
      .filter((shareArtworkId) => _.has(artworks, shareArtworkId))
      .map((artworkId) => artworks[artworkId])
      .value();

    return sharedArtworks;
  }
);

export const selectShareRecordsByType = createSelector(
  selectShareRecords,
  selectUser,
  selectShareRecordListType,
  (shareRecords, user, shareRecordListType) => {
    const shareRecordsWithType = _(shareRecords)
      .filter((shareRecord) => shareRecord?.[shareRecordListType]?.id === user.id)
      .orderBy("dateShared", "desc")
      .value();

    return shareRecordsWithType;
  }
);

export const selectSelectedCollection = createSelector(
  selectSelectedCollectionId,
  selectCollections,
  (selectedCollectionId, collections) => {
    return collections[selectedCollectionId];
  }
);

export const selectSelectedCollectionArtworks = createSelector(
  selectSelectedCollectionId,
  selectArtworks,
  selectSearch,
  (selectedCollectionId, artworks, search) => {
    return _(artworks)
      .filter((artwork) => _.includes(selectedCollectionId, artwork.artworkCollection) && artwork.isActive)
      .thru((artworks) => {
        if (search.searchTerm !== "") {
          const searchTags = search.searchTerm.split(" ");
          return artworks.filter((artwork) => artworkContainsSearchTags(searchTags, artwork));
        }
        return artworks;
      })
      .value();
  }
);

export const selectSelectedCollectionArtworksCategories = createSelector(
  selectSelectedCollectionArtworks,
  selectSearch,
  (artworks, search) => {
    let definedCategories;

    switch (search.sortBy) {
      case "artist":
      case "artworkValues.year":
      case "artworkValues.currentOwner":
        definedCategories = _.chain(artworks).map(search.sortBy).uniq().value();
        break;
      case "status":
        definedCategories = _.chain(artworks)
          .map(search.sortBy)
          .map((category) => (category ? DEFAULT_ARTWORK_STATUSES[category].label : undefined))
          .uniq()
          .value();
        break;
      case "dateCreated":
        definedCategories = _.chain(artworks)
          .map((artwork) => artwork.dateCreated.split("T").shift())
          .uniq()
          .value();
        break;
      case "lastModified":
        definedCategories = _.chain(artworks)
          .map((artwork) =>
            artwork.hasOwnProperty("lastModified") ? artwork.lastModified.split("T").shift() : undefined
          )
          .uniq()
          .value();
        break;
      default:
        return [UiLabels.all];
    }

    console.log(definedCategories);

    return _(definedCategories)
      .orderBy((category) => {
        switch (search.sortBy) {
          case "artist":
          case "artworkValues.currentOwner":
            const splitArtistNames = _.chain(category)
              .trim()
              .split(" ")
              .map((name) => name.toLowerCase());
            const artistLastName = splitArtistNames.pop();
            return [artistLastName, ...splitArtistNames];
          case "artworkValues.year":
            return category === null || category === undefined ? "" : category;
          default:
            return category;
        }
      }, search.sortOrder)
      .value();
  }
);

export const selectCategoryArtworks = createSelector(
  selectCategory,
  selectSelectedCollectionArtworks,
  selectSearch,
  (category, artworks, search) => {
    return _.chain(artworks)
      .filter((artwork) => {
        switch (search.sortBy) {
          case "artist":
          case "artworkValues.year":
          case "artworkValues.currentOwner":
            return _.get(artwork, search.sortBy) === category;
          case "status":
            return (
              _.get(artwork, search.sortBy) === _.findKey(DEFAULT_ARTWORK_STATUSES, ({ label }) => label === category)
            );
          case "dateCreated":
          case "lastModified":
            const sortedValue = _.get(artwork, search.sortBy);
            return (sortedValue ? sortedValue.split("T").shift() : sortedValue) === category;
          default:
            return true;
        }
      })
      .orderBy((artwork) => [artwork.archiveArtworkNumber, artwork.artworkValues.year || ""], "desc")
      .map("id")
      .value();
  }
);

export const selectCategoryArtworksWithCategoryFromSelection = createSelector(
  selectSelectedCategory,
  selectSelectedCollectionArtworks,
  selectSearch,
  (category, artworks, search) => {
    return _.chain(artworks)
      .filter((artwork) => {
        switch (search.sortBy) {
          case "artist":
          case "artworkValues.year":
          case "artworkValues.currentOwner":
            return _.get(artwork, search.sortBy) === category;
          case "status":
            return (
              _.get(artwork, search.sortBy) === _.findKey(DEFAULT_ARTWORK_STATUSES, ({ label }) => label === category)
            );
          case "dateCreated":
          case "lastModified":
            const sortedValue = _.get(artwork, search.sortBy);
            return (sortedValue ? sortedValue.split("T").shift() : sortedValue) === category;
          default:
            return true;
        }
      })
      .orderBy((artwork) => [artwork.archiveArtworkNumber, artwork.artworkValues.year || ""], "desc")
      .map("id")
      .value();
  }
);

export const selectArtworkNavigationIndex = createSelector(
  selectCategoryArtworksWithCategoryFromSelection,
  selectArtworkId,
  (artworks, selectedArtworkId) => {
    return _.findIndex(artworks, (artworkId) => artworkId === selectedArtworkId);
  }
);

export const selectSelectedArtworks = createSelector(
  selectArtworks,
  selectSelectedArtworkIds,
  (artworks, artworkIds) => {
    const selectedArtworks = _.map(artworkIds, (artworkId) => artworks[artworkId]);

    return selectedArtworks;
  }
);
