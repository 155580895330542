import _ from "lodash";
import { createSelector } from "reselect";
import { ContactContainsSearchTags } from "../../utils";
import { selectSearch } from "./searchSelectors";

export const selectContacts = (state) => state.contact.contacts.byId;
export const selectContactId = (state, contactId) => contactId;

export const selectContactById = createSelector(selectContacts, selectContactId, (contacts, contactId) => {
  return contacts?.[contactId];
});

export const selectAllContactsList = createSelector(selectContacts, selectSearch, (contacts, search) => {
  let contactsList = _.map(contacts, (contact) => {
    return contact;
  });

  return _(contacts)
    .thru((contacts) => {
      if (search.searchTermContact !== "") {
        const searchTags = search.searchTermContact.split(" ");

        const filter = contactsList.filter((contact) => ContactContainsSearchTags(searchTags, contact));
        return filter;
      }
      return contacts;
    })
    .value();
});
