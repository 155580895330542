import React, { Component } from "react";
import styled from "styled-components";
import { color, layout, variant, flexbox } from "styled-system";
import { isOverlapping } from "../../utils";

import TextareaAutosize from "react-textarea-autosize";
import { Flex, Icon, Text } from "../fundamentals";

const StyledInput = styled(Flex)`
  position: relative;
  align-items: center;
  flex-shrink: 0.2;
  overflow: hidden;
  input,
  textarea {
    align-self: stretch;
    width: 100%;
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: inherit;
    resize: none;
    padding: 0px;
    &:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0px 9999px ${(props) => props.theme.colors.grey[10]};
    }
    &:-webkit-autofill:hover {
      -webkit-box-shadow: inset 0 0 0px 9999px ${(props) => props.theme.colors.grey[20]};
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  ${(props) =>
    variant({
      prop: "inputStyle",
      variants: {
        primary: {
          minHeight: props.minHeight ? props.minHeight : "40px",
          [props.error && !props.active ? "border" : "borderBottom"]: [
            props.error && !props.active ? "1px solid" : "2px solid",
          ],
          borderColor: props.error ? "red" : "black",
          bg: props.error ? "rgba(255, 55, 55, 0.1)" : "#F3F4FF",

          "&:hover": {
            bg: "#F3F4FF",
          },
          "&:focus-within": {
            bg: props.focusBackground ?? "#F3F4FF",
            borderBottomColor: props.error ? "red" : "#5E54FF",
            borderBottomWidth: "2px",
            input: {
              color: props.focusColor,
            },
          },
          "input, textarea": {
            fontSize: "14px",
            lineHeight: "body",
            minHeight: props.minHeight,
            "&::placeholder": {
              color: "black",
            },
          },
          textarea: {
            paddingTop: "xxxs",
            paddingBottom: "xxxs",
          },
        },
        pill: {
          bg: "white",
          height: "32px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
          borderRadius: "16px",
          paddingLeft: "s",
          paddingRight: "s",
          paddingTop: "xxxs",
          paddingBottom: "xxxs",
          fontSize: "body",
          lineHeight: "body",
          color: "grey.60",
        },
      },
    })(props)}
  ${(props) => props.disabled && `border: none; pointer-events: none; `}
  ${color}
  ${layout}
  ${flexbox}
`;

class Input extends Component {
  constructor(props) {
    super(props);
    this.onAllChange = this.onAllChange.bind(this);
    this.onAllFocus = this.onAllFocus.bind(this);
    // this.onAllBlur = this.onAllBlur.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.state = { showPassword: false };
    this.containerRef = React.createRef();
    this.inputRef = props.customRef || React.createRef();
  }

  onAllChange(value) {
    const {
      input: { onChange, value: prevValue },
      meta,
      additionalOnChange,
      options: { type } = {},
    } = this.props;
    const parsedValue = value && type === "number" ? parseFloat(value.target.value) : value.target.value;
    onChange(parsedValue);
    if (additionalOnChange) additionalOnChange(parsedValue, { ...meta, prevValue });
  }

  onAllFocus() {
    const {
      input: { value, onFocus },
      meta,
      additionalOnFocus,
      preventOverlapRef,
      scrollingRef,
    } = this.props;
    if (onFocus) onFocus();
    if (preventOverlapRef && scrollingRef.current && isOverlapping(this.containerRef, preventOverlapRef)) {
      scrollingRef.current.scrollBy(0, 100);
    }
    if (additionalOnFocus) additionalOnFocus(value, meta);
  }

  // onAllBlur() {
  //   const {
  //     input: { onBlur, value },
  //     meta,
  //     additionalOnBlur
  //   } = this.props;
  //   if (onBlur) onBlur();
  //   // if (additionalOnBlur) additionalOnBlur(value, meta);
  // }

  renderIcon() {
    const { meta = {}, loading, activeIcon, icon, iconSize, iconColor, password } = this.props;
    const { showPassword } = this.state;
    if (activeIcon && meta.active) return <Icon icon={activeIcon} dark={true} size={30} color={iconColor} />;
    else if (loading) return <Icon icon={"Loader"} spin={true} color={iconColor} />;
    else if (password)
      return (
        <Icon
          icon={showPassword ? "Eye" : "EyeOff"}
          color="grey.100"
          size="20"
          cursor="pointer"
          onClick={() => this.setState({ showPassword: !showPassword })}
        />
      );
    else if (icon) return <Icon icon={icon} color={iconColor} pl="xxs" pr="xxs" size={iconSize} />;
  }

  renderSuffix(suffix) {
    if (typeof suffix === "string") {
      return (
        <Text size="body" color="black" position="absolute" right="xxs">
          {suffix}
        </Text>
      );
    } else return suffix;
  }

  render() {
    const {
      input: {
        onChange,
        onFocus,
        // onBlur,
        ...input
      },
      meta: { error, ...meta } = {},
      type,
      inputStyle = "primary",
      hideError,
      label,
      flip,
      warning,
      info,
      options = {},
      disabled,
      height,
      minHeight,
      required,
      password,
      prefix,
      suffix,
      focusBackground,
      focusColor,
      placeholder,
      ...props
    } = this.props;
    const displayError = error && meta.touched && (meta.visited || meta.submitFailed) && error;
    const feedback = ((meta.active || !meta.pristine) && warning) || info;
    const inputProps = {
      ref: this.inputRef,
      value: input.value,
      onChange: this.onAllChange,
      onFocus: this.onAllFocus,
      // onBlur: this.onAllBlur,
      disabled,
      ...input,
      ...Object.assign({ autoComplete: input.name }, options),
    };
    return (
      <Flex
        alignItems="stretch"
        ref={this.containerRef}
        flexDirection="column"
        userSelect={input.value && input.value !== "" ? "text" : "none"}
        {...props}
      >
        {label && (
          <Text
            fontSize="small"
            lineHeight="button"
            color={disabled ? "grey.60" : "grey.90"}
            mb="xxxs"
            userSelect="none"
          >
            {label} {required ? "*" : ""}
          </Text>
        )}
        <StyledInput
          inputStyle={inputStyle}
          disabled={disabled}
          error={displayError}
          pl="xxs"
          pr="xxs"
          mb={!displayError && "s"}
          height={height}
          minHeight={minHeight}
          focusBackground={focusBackground}
          focusColor={focusColor}
          {...meta}
        >
          {prefix}
          {!flip && !password && this.renderIcon()}
          {type === "textarea" ? (
            <TextareaAutosize style={{ textAlign: "justify" }} {...inputProps} />
          ) : (
            <input
              type={password && !this.state.showPassword ? "password" : type}
              placeholder={placeholder}
              {...inputProps}
            />
          )}
          {(flip || password) && this.renderIcon()}
          {this.renderSuffix(suffix)}
        </StyledInput>
        {inputStyle !== "pill" && displayError && (
          <Flex
            alignItems="center"
            color={displayError ? "red" : warning ? "amber.90" : "grey.80"}
            overflow="hidden"
            height={hideError && !displayError && !feedback ? 4 : 24}
            mb="xxs"
          >
            {(feedback || displayError) && <Icon icon="Info" size="14" mr="xxxs" />}
            <Text fontSize="smaller" whiteSpace="nowrap">
              {displayError || feedback}
            </Text>
          </Flex>
        )}
      </Flex>
    );
  }
}

export { Input };
