import { InputValues, Headings, Instructions, InputLabels } from "../localisation";

export const DEFAULT_ARTWORK_STATUSES = {
  available: { label: InputValues.available, color: "#AAEE98" },
  reserved: { label: InputValues.reserved, color: "amber.30" },
  sold: { label: InputValues.sold, color: "#F1B8AB" },
  onsite: { label: InputValues.onsite, color: "#A1CEEE" },
  loan: { label: InputValues.onLoan, color: "#EAF39F" },
  transit: { label: InputValues.inTransit, color: "#E3C7A4" },
  production: { label: InputValues.inProduction, color: "#DFDFDF" },
};

export const SUPPORTED_CURRENCIES = ["EUR", "USD", "GBP", "CHF", "CNY", "HKD"];

export const SORT_OPTIONS = {
  "*": { label: "All", sortOrder: "asc" },
  artist: { label: "Artist Name", sortOrder: "asc" },
  "artworkValues.year": { label: "Year", sortOrder: "desc" },
  "artworkValues.currentOwner": { label: "Current Owner", sortOrder: "desc" },
  status: { label: "Status", sortOrder: "asc" },
  dateCreated: { label: "Date Created", sortOrder: "desc" },
  lastModified: { label: "Last Updated", sortOrder: "desc" },
};

export const ARTWORK_FORM_SECTIONS = {
  artworkImages: { title: Headings.images, name: "images", subtitle: Instructions.addImages, mb: "m" },
  collection: {
    title: Headings.collection,
    name: "collection",
    minWidth: "280px",
    maxWidth: "500px",
    userSelect: "none",
    mb: "l",
  },
  generalInformation: {
    title: Headings.generalInformation,
    name: "generalInformation",
    minWidth: "280px",
    maxWidth: "500px",
    userSelect: "none",
    mb: "l",
  },
  artworkLocation: {
    title: Headings.location,
    name: "location",
    minWidth: "280px",
    maxWidth: "500px",
    userSelect: "none",
    mb: "l",
  },
  artworkValue: {
    title: Headings.value,
    name: "value",
    minWidth: "280px",
    maxWidth: "500px",
    userSelect: "none",
    mb: "l",
  },
  additionalInformation: {
    title: Headings.additionalInformation,
    name: "additionalInformation",
    minWidth: "280px",
    maxWidth: "500px",
    userSelect: "none",
    mb: "l",
  },
};

export const CONTACT_TYPES = [
  {
    label: InputLabels.artistType,
    value: "Artist",
  },
  {
    label: InputLabels.curator,
    value: "Curator",
  },
  {
    label: InputLabels.gallerist,
    value: "Gallerist",
  },
  
  {
    label: InputLabels.collector,
    value: "Collector",
  },
  {
    label: InputLabels.journalist,
    value: "Journalist",
  },
  {
    label: InputLabels.technician,
    value: "Technician",
  },
  {
    label: InputLabels.museum,
    value: "Museum",
  },
  {
    label: InputLabels.auction,
    value: "Auction house",
  },
  {
    label: InputLabels.writer,
    value: "Writer",
  },
  {
    label: InputLabels.historian,
    value: "Art historian",
  },
  {
    label: InputLabels.transport,
    value: "Transport",
  },
  {
    label: InputLabels.insurance,
    value: "Insurance",
  },

  {
    label: InputLabels.fair,
    value: "Fair",
  },
  {
    label: InputLabels.institution,
    value: "Institution",
  },
  {
    label: InputLabels.vip,
    value: "VIP",
  },
  {
    label: InputLabels.sponsor,
    value: "Sponsor",
  },
  {
    label: InputLabels.hotel,
    value: "Hotel",
  },
  {
    label: InputLabels.catering,
    value: "Catering",
  },
];
