import axios from "axios";
import axiosRetry from "axios-retry";

function getAxiosApi(url, retries = 0) {
  const requester = axios.create({
    baseURL: url,
  });
  if (retries > 0) {
    axiosRetry(requester, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
    });
  }
  return requester;
}

export const SimplifyApi = getAxiosApi(process.env.REACT_APP_SIMPLIFY_API_URL, 0);
