import { UPDATE_SEARCH_TERM, UPDATE_SEARCH_TERM_CONTACT, UPDATE_SORT_BY_OPTION } from "./types";

export const updateSearchTerm = searchTerm => {
  return { type: UPDATE_SEARCH_TERM, payload: { searchTerm } };
};

export const updateSearchTermContact = searchTerm => {
  return { type: UPDATE_SEARCH_TERM_CONTACT, payload: { searchTerm } };
};

export const updateSortByOption = sortBy => {
  return { type: UPDATE_SORT_BY_OPTION, payload: { sortBy } };
};
