import { loadStripe } from "@stripe/stripe-js";

export const STRIPE_PLANS = {
  artist_monthly: {
    plan: "artist-monthly",
    alternative_plan: "artist-yearly",
    price: 10,
    price_for_month:10,
    subtotal: 0,
    vat: 0,
    discount: null,
    label: "Artist Monthly",
    billing_period: "monthly",
    mr: "l",
  },
  artist_yearly: {
    plan: "artist-yearly",
    alternative_plan: "artist-monthly",
    price: 96,
    price_for_month: 8,
    subtotal: 0,
    vat: 0,
    discount: 15,
    label: "Artist Yearly",
    billing_period: "annually",
    mr: "l",
  },
  collector_monthly: {
    plan: "collector-monthly",
    alternative_plan: "collector-yearly",
    price: 29,
    price_for_month:29,
    subtotal: 0,
    vat: 0,
    discount: null,
    label: "Collector Monthly",
    billing_period: "monthly",
    mr: "l",
  },
  collector_yearly: {
    plan: "collector-yearly",
    alternative_plan: "collector-monthly",
    price: 300,
    price_for_month: 25,
    subtotal: 0,
    vat: 0,
    discount: 15,
    label: "Collector Yearly",
    billing_period: "annually",
    mr: "l",
  },
  gallerist_monthly: {
    plan: "gallerist-monthly",
    alternative_plan: "gallerist-yearly",
    price: 29,
    price_for_month:29,
    subtotal: 0,
    vat: 0,
    discount: null,
    label: "Gallerist Monthly",
    billing_period: "monthly",
    mr: "l",
  },
  gallerist_yearly: {
    plan: "gallerist-yearly",
    alternative_plan: "gallerist-monthly",
    price: 300,
    price_for_month: 25,
    subtotal: 0,
    vat: 0,
    discount: 15,
    label: "Gallerist Yearly",
    billing_period: "annually",
    mr: "l",
  },
  art_professional_monthly: {
    plan: "art-professional-monthly",
    alternative_plan: "art-professional-yearly",
    price: 29,
    price_for_month:29,
    subtotal: 0,
    vat: 0,
    discount: null,
    label: "Art Professional Monthly",
    billing_period: "monthly",
    mr: "l",
  },
  art_professional_yearly: {
    plan: "art-professional-yearly",
    alternative_plan: "art-professional-monthly",
    price: 300,
    price_for_month: 25,
    subtotal: 0,
    vat: 0,
    discount: 15,
    label: "Art Professional Yearly",
    billing_period: "annually",
    mr: "l",
  },
  art_institution_monthly: {
    plan: "art-institution-monthly",
    alternative_plan: "art-institution-yearly",
    price: 29,
    price_for_month: 29,
    subtotal: 0,
    vat: 0,
    discount: null,
    label: "Art Institution Monthly",
    billing_period: "monthly",
    mr: "l",
  },
  art_institution_yearly: {
    plan: "art-institution-yearly",
    alternative_plan: "art-institution-monthly",
    price: 300,
    price_for_month: 25,
    subtotal: 0,
    vat: 0,
    discount: 15,
    label: "Art Institution Yearly",
    billing_period: "annually",
    mr: "l",
  },
};

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
