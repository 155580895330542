import React, { useEffect, useCallback, useState } from "react";
import _ from "lodash";
import localForage from "localforage";
import { useDispatch, useSelector } from "react-redux";
import { recacheImage } from "../../store/actions";
import { dataURLtoBlob } from "../../utils";

const WithImageCache = (WrappedComponent) => {
  const WithImageCacheComponent = ({ artworkId, image, urlSuffix, placeholder, isLogo, ...props }) => {
    const [src, setSrc] = useState("");
    const isOnline = useSelector((state) => state.app.isOnline);
    const localImageUrls = useSelector((state) => state.session.cachedImageUrls);
    const cachedImage = localImageUrls[image.localId];

    useEffect(() => {
      if (image.isFromServer) {
        if (image.url) setSrc(`${process.env.REACT_APP_S3_URL}${image.url}${urlSuffix}`);
      } else {
        if (cachedImage) setSrc(cachedImage.url);
      }

      if (isLogo) {
        if (image.isFromServer) {
          if (image.url) setSrc(`${process.env.REACT_APP_S3_URL}${image.url}`);
        } else {
          if (cachedImage) setSrc(cachedImage.url);
        }
      }
    }, [src, image.key, cachedImage, image.isFromServer]);

    return (
      <WrappedComponent
        key={image.key}
        src={src}
        effect="opacity"
        placeholderSrc={`${process.env.PUBLIC_URL}/images/brand-identity/logo-no-text.svg`}
        onError={(error) => {
          if (isOnline) {
            setTimeout(() => {
              setSrc("");
            }, 3000);
          }
        }}
        {...props}
      />
    );
  };

  return WithImageCacheComponent;
};

export { WithImageCache };
