import _ from "lodash";

export function createToast(content, options = {}) {
  const add = _.get(window, "__react_toast_provider.current.add");
  if (!add) {
    console.error("__react_toast_provider not set");
    return;
  }
  add(content, options);
}
