import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { forceSignOut } from "../../store/actions";
import { selectUser } from "../../store/selectors";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.forceSignout) dispatch(forceSignOut());
  }, [user.forceSignout]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user.session) {
          console.log("No Session");
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }

        // authorised and has accessLevel so return component
        return <Component {...props} />;
      }}
    />
  );
};

export { ProtectedRoute };

const UnProtectedRoute = ({ component: Component, ...rest }) => {
  const user = useSelector(selectUser);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.session && user.account.stripeCustomerId) {
          console.log("already logged in");
          // already logged in so redirect to archive page
          return <Redirect to={{ pathname: "/artworks", state: { from: props.location } }} />;
        }

        // unauthorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export { UnProtectedRoute };
