const breakpoints = ["1400px"];

export default {
  breakpoints,
  backgroundColor: "linear-gradient(180deg, #FFFFFF 15.62%, rgba(255, 153, 0, 0.5) 58.85%, #5E54FF 92.19%), #FFFFFF",
  fonts: {
    body: "Inter",
    bodyDmSans: "DM Sans",
  },
  fontSizes: {
    smallest: 10,
    smaller: 12,
    small: 14,
    body: 16,
    heading3: 20,
    heading2: 24,
    heading1: 32,
    hero: 56,
  },
  space: {
    none: 0,
    xxxs: 4,
    xxs: 8,
    xs: 12,
    s: 16,
    m: 24,
    l: 32,
    xl: 40,
    xxl: 48,
    xxxl: 64,
    mega: 80,
    hyper: 120,
  },
  lineHeights: {
    button: "16px",
    small: "20px",
    body: "24px",
    heading3: "24px",
    heading2: "32px",
    heading1: "40px",
    hero: "72px",
  },
  shadows: {
    small: "0px 2px 8px rgba(0, 0, 0, 0.08)",
    medium: "0px 6px 12px rgba(0, 0, 0, 0.1)",
    large: "0px 8px 32px rgba(0, 0, 0, 0.25)",
  },
  colors: {
    white: "#FFFFFF",
    black: "#2A2A2F",
    red: "#FF3737",
    green: "#28D96F",
    blue: {
      10: "#F3F4FF",
      20: "#DEE1FF",
      30: "#C8CEFF",
      40: "#9AA1FF",
      50: "#7274FF",
      60: "#5E54FF",
      70: "#563DFF",
      80: "#4F2BE6",
      90: "#4720CA",
      100: "#3E18AD",
    },
    amber: {
      10: "#FFFBF2",
      20: "#FFEFC6",
      30: "#FFE49A",
      40: "#FFD04E",
      50: "#FFBE0B",
      60: "#F7B400",
      70: "#EBAA00",
      80: "#DA9C00",
      90: "#C08900",
      100: "#9B6D00",
    },
    grey: {
      0: "#F9F9FA",
      10: "#F4F4F5",
      20: "#E8E7EA",
      30: "#DBDADF",
      40: "#C3C2C9",
      50: "#ACAAB3",
      60: "#95939C",
      70: "#7F7D86",
      80: "#6A6870",
      90: "#55535A",
      100: "#403F44",
    },
  },
};
