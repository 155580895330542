import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { resetApp } from "../../store/actions/AppActions";

import { Flex, Image, Text, Link } from "../fundamentals";
import { Button } from "../elements";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  refreshPage() {
    window.location.reload();
  }

  componentDidCatch(error, errorInfo) {
    console.error(errorInfo);
    this.setState({ error: error.message });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Flex flexDirection="column" alignItems="center" mb="l">
          <Image
            src={`${process.env.PUBLIC_URL}/images/brand-identity/logo-no-text.svg`}
            alt="logo"
            height="48px"
            mb="m"
          />
          <Text size="heading2" pb="m">
            Something went wrong
          </Text>
          <Text size="heading2" pb="m">
            {this.state.error}
          </Text>
          <Text size="heading3">To fix this you could try</Text>
          <ol>
            <Text pb="xxxs" listItem>
              <Button buttonStyle="text" size="medium" pl="none" pr="xxxs" onClick={this.refreshPage}>
                Reload
              </Button>
              the page
            </Text>
            <Text listItem>
              <Button buttonStyle="text" size="medium" pl="none" pr="xxxs" onClick={this.props.resetApp}>
                Clear
              </Button>
              your cookies
            </Text>
            <Text listItem>
              <Link href="mailto:contact@simplify.art">
                <Button buttonStyle="text" size="medium" pl="none" pr="xxxs">
                  Contact us
                </Button>
              </Link>
              for support
            </Text>
          </ol>
        </Flex>
      );
    }
    return this.props.children;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ resetApp: resetApp }, dispatch);
}

const ConnectedErrorBoundary = connect(null, mapDispatchToProps)(ErrorBoundary);

export { ConnectedErrorBoundary as ErrorBoundary };
