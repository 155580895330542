import React, { useState, useEffect, useRef } from "react";

export const StickyObservable = ({ children, options }) => {
  const customRef = useRef();
  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    const element = customRef.current;
    function handleObservable([entry]) {
      setSticky(!entry.isIntersecting);
    }
    const observer = new IntersectionObserver(handleObservable, options);
    element && observer.observe(element);
    return () => element && observer.unobserve(element);
  });
  return React.cloneElement(children, { customRef, sticky });
};
