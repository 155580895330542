import React from "react";
import styled from "styled-components";
import { space, color, border, layout, position, flexbox } from "styled-system";

const StyledSeparator = styled.hr`
  width: 100%;
  border: none;
  ${color}
  ${space}
  ${border}
  ${layout}
  ${position}
  ${flexbox}
`;

const Separator = ({ bg = "grey.30", height = "1px", ...props }) => (
  <StyledSeparator bg={bg} height={height} {...props} />
);

export { Separator };
