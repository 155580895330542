import React from "react";
import styled from "styled-components";
import { flexbox, space, color, typography, layout, border, position, shadow, background } from "styled-system";

const StyledForm = styled.form`
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${border}
  ${shadow}
  ${flexbox}
  ${position}
  ${background}
  ${props => props.whiteSpace && `white-space: ${props.whiteSpace};`}
`;

const Form = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledForm ref={ref} {...props}>
      {children}
    </StyledForm>
  );
});

export { Form };
