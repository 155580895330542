import { Headings, FeatureDescriptions } from "../localisation";

export const TUTORIAL_NAMES = {
  waltersCubeButton: "walters_cube_button_tutorial",
  whiteLabeledExports: "white_labeled_exports_tutorial"
};

export const JOYRIDE_STEPS = {
  [TUTORIAL_NAMES.waltersCubeButton]: [
    {
      target: "#walters_cube_button",
      title: Headings.waltersCubeExhibitions,
      content: FeatureDescriptions.waltersCubeExhibitions,
      disableBeacon: true,
      disableOverlay: true,
      offset: 0
    }
  ],
  [TUTORIAL_NAMES.whiteLabeledExports]: [
    {
      target: "#profile_dropdown_button",
      title: Headings.whiteLabeledExports,
      content: FeatureDescriptions.whiteLabeledExports,
      disableBeacon: true,
      styles: { options: { overlayColor: "rgba(0, 0, 0, 0)" } },
      offset: 0
    }
  ]
};
