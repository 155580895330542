import React from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { space, variant } from "styled-system";
import css from "@styled-system/css";

import {
  selectAllArtworks,
  clearAllSelectedArtworks,
  updateSearchTerm,
  updateSortByOption,
  openModal,
} from "../../store/actions";
import { ButtonLabels, DynamicLabels, InputPlaceholders, InputLabels } from "../../localisation";
import { SORT_OPTIONS } from "../../config";
import { selectSearch, selectSelectedArtworkIds, selectSelectedCollectionArtworks } from "../../store/selectors";

import { Field, reduxForm } from "redux-form";
import { Flex, Text } from "../fundamentals";
import { Input, DropdownSelect, Button } from "../elements";

const StyledCollectionHeader = styled(Flex)`
  white-space: nowrap;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  border-bottom-width: 1px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;

  ${variant({
    prop: "selection",
    variants: {
      false: {
        bg: "grey.10",
        color: "grey.90",
      },
      true: {
        bg: "blue.60",
        color: "white",
      },
    },
  })}
  ${variant({
    prop: "sticky",
    variants: {
      true: {
        "::after": {
          content: '""',
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "5px",
          zIndex: "-1",
          boxShadow: "0px 4px 6px rgba(0,0,0,0.08)",
        },
      },
      false: { borderBottomStyle: "solid" },
    },
  })}
  ${(props) =>
    css({
      borderBottomColor: "grey.20",
      paddingLeft: "l",
      paddingRight: "m",
      paddingTop: ["xs", "s"],
      paddingBottom: ["xs", "s"],
      borderColor: "grey.30",
    })}
  ${space}
`;

const CollectionHeader = ({ customRef, hideButtons, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedArtworkIds = useSelector(selectSelectedArtworkIds);
  const artworks = useSelector(selectSelectedCollectionArtworks);
  const artworkCountInCollection = artworks.length;
  const isAnyArtworkSelected = selectedArtworkIds.length > 0;
  const search = useSelector(selectSearch);

  const handleOnSearch = _.debounce((searchTerm) => dispatch(updateSearchTerm(searchTerm)), 50);

  const handleOnSortBy = _.debounce((sortBy) => dispatch(updateSortByOption(sortBy)), 50);

  const handleOnArtworkDetailsClick = () => {
    if (selectedArtworkIds.length === 1) {
      history.push("/artworks/details", { source: history.location.pathname });
    } else {
      history.push("/artworks/multi-edit", { source: history.location.pathname });
    }
  };

  const handleSelectAllArtworks = () => {
    const artworkIds = _.map(artworks, ({ id }) => id);
    dispatch(selectAllArtworks(artworkIds));
  };

  const handleClearAllSelectedArtworks = () => {
    dispatch(clearAllSelectedArtworks());
  };

  const renderHeaderStyleButtons = () => {
    if (isAnyArtworkSelected)
      return (
        <Flex>
          <Button
            buttonStyle="secondary"
            size="small"
            icon="ExternalLink"
            inverted={true}
            onClick={() => dispatch(openModal("advanced_export"))}
            mr="m"
          >
            {ButtonLabels.export}
          </Button>

          <Button
            buttonStyle="secondary"
            size="small"
            icon="Share"
            inverted={true}
            onClick={() => dispatch(openModal("share"))}
            mr="m"
          >
            {ButtonLabels.share}
          </Button>

          <Button
            buttonStyle="secondary"
            size="small"
            icon="Edit2"
            inverted={true}
            mr="xxs"
            onClick={handleOnArtworkDetailsClick}
          >
            {ButtonLabels.details}
          </Button>

          <Button buttonStyle="text" size="small" icon="X" inverted={true} onClick={handleClearAllSelectedArtworks}>
            {ButtonLabels.cancel}
          </Button>
        </Flex>
      );
    else
      return (
        <Flex>
          <Button buttonStyle="text" size="medium" onClick={handleSelectAllArtworks}>
            {ButtonLabels.selectAll}
          </Button>
        </Flex>
      );
  };

  return (
    <StyledCollectionHeader ref={customRef} selection={isAnyArtworkSelected} {...props}>
      <Flex alignItems="center" flexShrink={0} flexGrow={1}>
        <Text size="body" mr="m" flexShrink={0} userSelect="none">
          {DynamicLabels.numArtworks(isAnyArtworkSelected ? selectedArtworkIds.length : artworkCountInCollection)}
        </Text>
        <Field
          name="searchTerm"
          icon="Search"
          iconSize={19}
          inputStyle="pill"
          options={{ placeholder: InputPlaceholders.searchArtworks, value: search.searchTerm }}
          onChange={handleOnSearch}
          hideError={true}
          flex={1}
          maxWidth="275px"
          mr="m"
          component={Input}
        />
        <Field
          name="sortBy"
          component={DropdownSelect}
          inverted={isAnyArtworkSelected}
          label={InputLabels.sortBy}
          options={SORT_OPTIONS}
          onChange={handleOnSortBy}
          stateful
        />
      </Flex>
      {!hideButtons && renderHeaderStyleButtons()}
    </StyledCollectionHeader>
  );
};

const CollectionHeaderWithSearchForm = reduxForm({
  form: "artworks.filter",
})(CollectionHeader);

export { CollectionHeaderWithSearchForm as CollectionHeader };
