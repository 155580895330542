import _ from "lodash";

export const artworkContainsSearchTags = (searchTags, artwork) => {
  const { archiveArtworkNumber, artworkValues, status } = artwork;
  return _.every(searchTags, (tag) => {
    if (artwork.artist.toLowerCase().indexOf(tag.toLowerCase()) !== -1) return true;
    return _.some(
      { archiveArtworkNumber, status, ...artworkValues },
      (value) => value && value.toString().toLowerCase().indexOf(tag.toLowerCase()) !== -1
    );
  });
};

export const ContactContainsSearchTags = (searchTags, contact) => {
  return _.every(searchTags, (tag) => {
    return _.some(
      { ...contact, ...contact?.address },
      (value) => value && value.toString().toLowerCase().indexOf(tag.toLowerCase()) !== -1
    );
  });
};
