import { EXECUTE_JOYRIDE, EXECUTE_TUTORIAL, NEW_TUTORIAL_STEP, TUTORIAL_COMPLETED } from "../actions/types";

export const executeJoyride = name => {
  return { type: EXECUTE_JOYRIDE, payload: { name } };
};

export const executeTutorial = name => {
  return { type: EXECUTE_TUTORIAL, payload: { name } };
};

export const newTutorialStep = step => {
  return { type: NEW_TUTORIAL_STEP, payload: { step } };
};

export const tutorialCompleted = () => {
  return { type: TUTORIAL_COMPLETED };
};
