import { useEffect } from "react";
import { useThrottle } from "@react-hook/throttle";
import useEvent from "@react-hook/event";

const getRefScroll = (ref = {}) => {
  if (ref.scrollTop && ref.scrollTop !== 0) return ref.scrollTop;
  if (ref.offsetTop && ref.offsetTop !== 0) return ref.offsetTop;
  if (ref.scrollY && ref.scrollY !== 0) return ref.scrollY;
  if (ref.pageYOffset && ref.pageYOffset !== 0) return ref.pageYOffset;
  return 0;
};

export default ({ ref = window === "undefined" ? null : window, fps = 30 }) => {
  const [state, setState] = useThrottle(0, fps, true);
  useEffect(() => {
    setState(getRefScroll(ref));
  }, [ref, setState]);
  useEvent(ref, "scroll", e => {
    setState(getRefScroll(e.currentTarget));
  });
  return state;
};
