import React from "react";
import { useScroll } from "../hooks";

import { Flex } from "../fundamentals";
import { Button } from "../elements";

export const BackToTop = ({ scrollingRef, showBoundary = 150, scrollTo, ...props }) => {
  const scroll = useScroll({ ref: scrollingRef });
  const hide = scroll < showBoundary;
  function scrollToTop() {
    if (scrollTo) scrollTo(0);
    else scrollingRef ? scrollingRef.scrollTo(0) : window.scrollTo({ top: 0 });
  }
  return (
    <Flex position="fixed" justifyContent="flex-end" pointerEvents="none" width="100%" maxWidth="1680px" bottom="l">
      <Button
        buttonStyle="text"
        size="medium"
        bg="blue.60"
        icon="ChevronUp"
        iconSize={21}
        borderRadius="2px"
        opacity={hide ? 0 : 1}
        disabled={hide}
        onClick={scrollToTop}
        transition="opacity 0.3s"
        p="xs"
        mr="xxl"
        inverted
      />
    </Flex>
  );
};
