import localForage from "localforage";
import _ from "lodash";

export function dataURLtoBlob(dataURL) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURL.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURL
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export async function cachedDataUrlToFile(key) {
  const dataUrl = await localForage.getItem(key);
  if (dataUrl) {
    const urlSegments = dataUrl.split(",");
    const mime = urlSegments[0].match(/:(.*?);/)[1];
    const byteString = atob(urlSegments[1]);

    let n = byteString.length;
    const filebuilder = new Uint8Array(n);

    while (n--) {
      filebuilder[n] = byteString.charCodeAt(n);
    }
    return new File([filebuilder], urlSegments[2], { type: mime });
  }
}
