import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { space, color, layout, position } from "styled-system";
import css from "@styled-system/css";
import { clickedInsideNode } from "../../utils";

import { Box, Flex } from "../fundamentals";
import { Button } from "../elements";

const StyledDropdown = styled(Box)`
  position: absolute;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  z-index: 100;
  ${css({
    marginLeft: "m",
    paddingTop: "xxs",
    paddingBottom: "xxs",
    backgroundColor: "white"
  })};
  ${color}
  ${space}
  ${layout}
  ${position}
`;

const StyledDropdownItem = styled(Flex)`
  border-radius: 3px;
  transition: background 0.25s;
  ${css({
    "&:hover": {
      backgroundColor: "grey.20"
    }
  })};
  ${space}
`;

export const DropdownContainer = ({ children, component, allowInnerClicks, draggable, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [mouseDownedInside, setMouseDownedInside] = useState(false);
  const containerRef = useRef();
  const componentRef = useRef();

  const handleMouseDown = e => setMouseDownedInside(clickedInsideNode(e, containerRef));
  const handleClick = e => {
    const insideContainer = clickedInsideNode(e, containerRef);
    setIsOpen(
      (insideContainer || (draggable && mouseDownedInside && !insideContainer)) &&
        (!isOpen || (isOpen && allowInnerClicks && !clickedInsideNode(e, componentRef)))
    );
    mouseDownedInside && setMouseDownedInside(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const Component = component;
  return (
    <Box ref={containerRef}>
      <Component ref={componentRef} {...props} />
      {isOpen && children}
    </Box>
  );
};

const Dropdown = ({ children, stateful, ...props }) => {
  const dropdownRef = useRef();
  const [right, setRight] = useState();
  useEffect(() => {
    if (dropdownRef.current.getBoundingClientRect().right + 1 > document.body.clientWidth) setRight("xxs");
  }, []);
  return (
    <StyledDropdown ref={dropdownRef} right={right} {...props}>
      {stateful ? children.map(child => React.cloneElement(child, { stateful })) : children}
    </StyledDropdown>
  );
};

const DropdownItem = ({ label, stateful, active, inactiveColor, icon, onSelect, preventDefault, ...props }) => {
  return (
    <StyledDropdownItem {...props}>
      <Button
        buttonStyle="text"
        size="medium"
        icon={active && stateful ? "Check" : icon || null}
        iconOffset="s"
        color={!active ? (inactiveColor ? inactiveColor : "black") : null}
        p="xs"
        pl={(active || icon) && stateful ? "s" : stateful ? "xxl" : "m"}
        pr={stateful ? "xxl" : "m"}
        flex={1}
        onClick={onSelect}
        preventDefault
      >
        {label}
      </Button>
    </StyledDropdownItem>
  );
};

export { Dropdown, DropdownItem };
