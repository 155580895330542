import React from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { addSelectedArtwork, removeSelectedArtwork } from "../../store/actions";
import { selectCategoryArtworks, selectSelectedArtworkIds } from "../../store/selectors";
import { ButtonLabels, DynamicLabels, UiLabels } from "../../localisation";

import { Box, Flex } from "../fundamentals";
import { Text } from "../fundamentals";
import { Artwork, Button } from "../elements";

const ArtworkCategory = React.forwardRef(({ category, index, readOnly, ...props }, ref) => {
  const dispatch = useDispatch();
  const selectedArtworkIds = useSelector(selectSelectedArtworkIds);
  const artworkIds = useSelector(state => selectCategoryArtworks(state, category));
  const anySelected = artworkIds.some(id => selectedArtworkIds.includes(id));
  const allSelected = anySelected && artworkIds.every(id => selectedArtworkIds.includes(id));

  return (
    <Box ref={ref} pt="m" pl="l" pr="l" pb="m" {...props}>
      <Text size="heading3" color="black">
        {moment(category, "YYYY-MM-DD", true).isValid()
          ? moment(category).format("MMMM Do YYYY")
          : category || UiLabels.unspecified}
      </Text>
      <Flex justifyContent="flex-start">
        <Text fontSize="small" lineHeight="small" color="grey.80" pt="2px" userSelect="none">
          {DynamicLabels.numArtworks(artworkIds.length)}
        </Text>
        {!readOnly && (
          <Flex pl="xs">
            {anySelected && (
              <Button
                buttonStyle="text"
                size="small"
                onClick={() => artworkIds.forEach(id => dispatch(removeSelectedArtwork(id)))}
                pr="none"
              >
                {ButtonLabels.clear}
              </Button>
            )}
            {!allSelected && (
              <Button
                buttonStyle="text"
                size="small"
                onClick={() => artworkIds.forEach(id => dispatch(addSelectedArtwork(id)))}
              >
                {ButtonLabels.selectAll}
              </Button>
            )}
          </Flex>
        )}
      </Flex>
      <Flex flexWrap="wrap" mt="m">
        {artworkIds.map((id, artworkIndex) => (
          <Artwork key={id} id={id} category={category} readOnly={readOnly} />
        ))}
      </Flex>
    </Box>
  );
});

export { ArtworkCategory };
