import {
  CREATE_CONTACT,
  CREATE_CONTACT_LOCALLY,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  DELETE_CONTACTS,
  DELETE_CONTACT,
  DELETE_CONTACT_FAILURE,
  DELETE_CONTACT_SUCCESS,
  EDIT_CONTACT,
  EDIT_CONTACT_FAILURE,
  EDIT_CONTACT_LOCALLY,
  EDIT_CONTACT_SUCCESS,
  UPLOAD_CONTACT_IMAGE,
  UPLOAD_CONTACT_IMAGE_FAILURE,
  UPLOAD_CONTACT_IMAGE_SUCCESS,
  SYNC_CONTACTS,
  DOWNLOAD_CONTACTS,
  DOWNLOAD_CONTACTS_SUCCESS,
} from "./types";

export const createContact = ({ status, image, ...formValues }, tempContactId) => {
  return {
    type: CREATE_CONTACT,
    payload: {
      tempContactId,
      dateCreated: new Date().toISOString(),
      status,
      image,
      ...formValues,
    },
  };
};

export const createContactLocally = (contact) => {
  return {
    type: CREATE_CONTACT_LOCALLY,
    payload: { ...contact },
  };
};

export const createContactSuccess = (tempContactId, response) => {
  return { type: CREATE_CONTACT_SUCCESS, payload: { tempContactId, ...response } };
};

export const createContactFailure = () => {
  return { type: CREATE_CONTACT_FAILURE, payload: {} };
};

export const uploadContactImage = (contactId, imageLocalId, image) => {
  return { type: UPLOAD_CONTACT_IMAGE, payload: { contactId, imageLocalId, image } };
};

export const uploadContactImageSuccess = (contactId, connectionId, imageToConnect) => {
  return { type: UPLOAD_CONTACT_IMAGE_SUCCESS, payload: { contactId, connectionId, imageToConnect } };
};

export const uploadContctImageFailure = () => {
  return { type: UPLOAD_CONTACT_IMAGE_FAILURE, payload: {} };
};

export const editContact = ({ id, status, images, ...formValues }) => {
  return {
    type: EDIT_CONTACT,
    payload: {
      id,
      lastModified: new Date().toISOString(),
      status,
      images,
      formValues,
    },
  };
};

export const editContactLocally = (contact) => {
  return { type: EDIT_CONTACT_LOCALLY, payload: { ...contact } };
};

export const editContactSuccess = (contactId, response) => {
  return { type: EDIT_CONTACT_SUCCESS, payload: { contactId, response } };
};

export const editContactFailure = () => {
  return { type: EDIT_CONTACT_FAILURE, payload: {} };
};

export const deleteContacts = (contactIds) => {
  return { type: DELETE_CONTACTS, payload: { contactIds } };
};

export const deleteContact = (contactId) => {
  return { type: DELETE_CONTACT, payload: { contactId } };
};

export const deleteContactSuccess = (contactId) => {
  return { type: DELETE_CONTACT_SUCCESS, payload: { contactId } };
};

export const deleteContactFailure = (contactId) => {
  return { type: DELETE_CONTACT_FAILURE, payload: { contactId } };
};

export const syncContacts = (contacts) => {
  return { type: SYNC_CONTACTS, payload: { contacts } };
};

export const downloadContacts = () => {
  return { type: DOWNLOAD_CONTACTS };
};
export const downloadContactsSuccess = (contacts) => {
  return { type: DOWNLOAD_CONTACTS_SUCCESS, payload: contacts };
};
export const downloadContactsFailure = () => {
  return { type: DOWNLOAD_CONTACTS, payload: [] };
};
