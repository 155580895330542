import moment from "moment";
import LocalizedStrings from "react-localization";

export const ButtonLabels = new LocalizedStrings({
  en: {
    exhibitions: "Exhibitions",
    contacts: "Contacts",
    contact: "Contact",
    listsLabel: "Lists",
    artworks: "Artworks",
    artwork: "Artwork",
    artists: "Artists",
    artist: "Artist",
    events: "Events",
    help: "Help",
    select: "Select",
    selectAll: "Select All",
    clear: "Clear",
    clearSearch: "Clear search",
    upload: "Upload",
    addImages: "Add Images",
    saveContact: "Save Contact",
    addArtwork: "Add Artwork",
    addContact: "Add Contact",
    saveArtwork: "Save Artwork",
    saveAndCopy: "Save and Copy",
    saveAndNew: "Save and New",
    reset: "Reset Changes",
    export: "Export",
    share: "Share",
    details: "Details",
    delete: "Delete",
    cancel: "Cancel",
    close:"Close",
    back: "Back",
    goBack: "Go Back",
    emailUs: "Email Us",
    next: "Next",
    prev: "Prev",
    create: "Create",
    cancelSubscriptionConfirmation: "Yes, cancel my subscription",
    changeBillingPeriodConfirmation: "Yes, change my billing period",
    createViewingRoom: "Create Viewing Room",
    more: "More",
    backToArtworks: "Back to artworks",
    backToContacts: "Back to contacts",
    settings: "Settings",
    contactUs: "Contact Us",
    signout: "Sign Out",
    forgotPassword: "Forgot Password?",
    accountAndSettings: "Account & Settings",
    organisationSettings: "Organisation Settings",
    subscriptionAndBilling: "Subscription & Billing",
    updateProfile: "Update Profile",
    changePassword: "Change password",
    deleteAccount: "Fully delete my account",
    updateOrganisation: "Update Organisation",
    incoming: "Incoming",
    outgoing: "Outgoing",
    download: "Download",
    preview: "Preview",
    login: "Log in",
    register: "Register",
    next: "Next",
    createAccount: "Create account",
    subscribe: "Subscribe",
    cancelSubscription: "Cancel subscription",
    changeBillingPeriod: "Change billing period",
    restartSubscription: "Restart subscription",
    sendResetCode: "Send Reset Code",
    sendMeTheResetCode: "Send me the reset code",
    requestAnotherOne: "Request another one",
    enterArchive: "Enter Archive",
    addDropbox: "Add from Dropbox",
    discovery: "Discovery",
    addCrates: "Add Crates",
    add: "Add",
    gotIt: "Got It",
    setAsCover: "Set as Cover",
    updateCard: "Update card",
    finish: "Finish",
    ok: "OK",
    continue: "Continue",
    skip: "Skip",
    startFreeTrial: "Start free trial",
    restartMySubscription: "Restart my subscription",
    update: "Update",
  },
});

export const LinkLabels = new LocalizedStrings({
  en: {
    signup: "Sign up",
    signin: "Sign in here",
    signinText: "Sign in",
    emailUs: "Email us",
    terms: "Terms of Service",
    privacyPolicy: "Privacy Policy.",
    here: "here",
  },
});

export const Headings = new LocalizedStrings({
  en: {
    welcomeBack: "Welcome back!",
    trySimplify: "Try simplify.art for free!",
    creditCardExpired: "Your credit card has expired",
    login: "Login",
    registration: "Create your Account",
    onboarding: "Account Details",
    artworks: "Artworks",
    artists: "Artists",
    viewingRoom: "Viewing Room",
    activity: "Activity",
    exportArtworks: "Export Artworks",
    generalInformation: "General Information",
    location: "Location",
    value: "Value",
    additionalInformation: "Additional Information",
    images: "Images",
    accountDetails: "Account Details",
    organisationSettings: "Organisation Settings",
    organisationDetails: "Organisation Details",
    subscription: "Subscription",
    subscriptionAndBilling: "Subscription & Billing",
    shareArtworks: "Share Artworks",
    workList: "New Work List",
    transportList: "New Transport List",
    insuranceList: "New Insurance List",
    priceList: "New Price List",
    invoice: "New Invoice",
    consignmentAgreement: "New Consignment Agreement",
    loanAgreement: "New Loan Agreement",
    handoffDocument: "New Handoff Document",
    certificate: "New Certificate",
    installationManual: "New Installation Manual",
    dataSheet: "New Data Sheet",
    storageLabels: "New Storage Labels",
    wallLabels: "New Wall Labels",
    createViewingRoom: "Create Viewing Room",
    choosePlan: "Choose a plan",
    choosePlanFreeTrial: "Choose a plan to start your free trial",
    chooseBillingPeriod: "Choose a billing period to start your free trial",
    forgotPassword: "Forgot password?",
    newArtwork: "Add New Artwork",
    changePassword: "Change Password",
    welcome: "Congratulations!",
    collection: "Collection",
    artworkLists: "Artwork Lists",
    salesAndAgreements: "Sales & Agreements",
    artworkDocuments: "Artwork Documents",
    labels: "Labels",
    csv: "CSV",
    newCsv: "New CSV",
    borrower: "Borrower",
    lender: "Lender",
    exhibition: "Exhibition",
    transport: "Transport",
    consignmentConditions: "Consignment Conditions",
    credits: "Credits",
    insurance: "Insurance",
    addCosts: "Add Costs",
    crates: "Crates",
    featureChangelog: "Whats New - Update ",
    cardDetails: "Card Details",
    updateCardDetails: "Update Card Details",
    waltersCubeExhibitions: "Walters Cube 3D Exhibitions",
    whiteLabeledExports: "White Label Your Exports",
    userType: "User Type",
    profileDetails: "Profile Details",
    selectWhichFitsBest: "Select which fits best",
    tellUsAboutYourSelf: "Tell us about yourself",
    annually: "Annually",
    monthly: "Monthly",
    orderSummary: "Order Summary",
    trialEnds: "Trial Ends",
    createdAt: "Created At",
    accountCreated: "Your account has been created!",
    creditCardUpdated: "Your credit card has been updated!",
    letsGetYouSimplifying: "Let’s get you back simplifying!",
    contacts: "Contacts",
    newContact: "New Contact",
  },
});

export const UiLabels = new LocalizedStrings({
  en: {
    loading: "Loading",
    lastUpdated: "Last Updated",
    include: "Include",
    multiple: "Multiple",
    comingSoon: "Coming Soon",
    perMonth: "per month",
    plan: "Plan",
    status: "Status",
    renewDate: "Renew Date",
    unsynced: "Unsynced",
    selected: "selected",
    cm: "cm",
    all: "All",
    version: "Version",
    cover: "Cover",
    unspecified: "Unspecified",
    cardNumber: "Card Number",
    expirationDate: "Expiration Date",
    subTotal: "Subtotal",
    vat: "Vat",
    dueNow: "Due Now",
    billingPeriodStart: "Billing Period Start",
    billingPeriodEnd: "Billing Period End",
    additional: "Additional",
    contacts: "Contacts",
    contactsSelected: "Contact selected",
  },
});

export const DynamicLabels = new LocalizedStrings({
  en: {
    numArtworks: (number) => `${number} Artwork${number > 1 ? "s" : ""}`,
    numArtists: (number) => `${number} Artist${number > 1 ? "s" : ""}`,
    updateArtworks: (number) => `Update ${number} Artwork${number > 1 ? "s" : ""}`,
    deleteArtworks: (number) => `Delete ${number} Artwork${number > 1 ? "s" : ""}`,
    sharedArtworks: (number) => `shared ${number} artwork${number > 1 ? "s" : ""}`,
    multiArtistEditWarning: (number) => `You are changing the Artist for ${number} selected Artworks`,
    multiTitleEditWarning: (number) => `You are changing the Title for ${number} selected Artworks`,
    multiDimensionsEditWarning: (number) => `You are changing the Dimensions for ${number} selected Artworks`,
    multiCollectionEditWarning: (number) => `You are changing the Collection for ${number} selected Artworks`,
    multiStatusEditWarning: (number) => `You are changing the Status for ${number} selected Artworks`,
    multiYearEditWarning: (number) => `You are changing the Year for ${number} selected Artworks`,
    multiEditionEditWarning: (number) => `You are changing the Edition for ${number} selected Artworks`,
    multiSeriesTitleEditWarning: (number) => `You are changing the Series for ${number} selected Artworks`,
    multiTechniqueEditWarning: (number) => `You are changing the Technique for ${number} selected Artworks`,
    multiWorkDescriptionEditWarning: (number) =>
      `You are changing the Work Description for ${number} selected Artworks`,
    multiLocationEditWarning: (number) => `You are changing the Location for ${number} selected Artworks`,
    multiPositionEditWarning: (number) => `You are changing the Position for ${number} selected Artworks`,
    multiCurrentOwnerEditWarning: (number) => `You are changing the Current Owner for ${number} selected Artworks`,
    multiSourceEditWarning: (number) => `You are changing the Source for ${number} selected Artworks`,
    multiPriceEditWarning: (number) => `You are changing the Price for ${number} selected Artworks`,
    multiInsuranceValueEditWarning: (number) => `You are changing Insurance Value for ${number} selected Artworks`,
    multiProductionCostsEditWarning: (number) =>
      `You are changing the Production Costs for ${number} selected Artworks`,
    multiFramingCostsEditWarning: (number) => `You are changing the Framing Costs for ${number} selected Artworks`,
    multiPhotoCopyrightOwnerEditWarning: (number) =>
      `You are changing the Photo Copyright Owner for ${number} selected Artworks`,
    multiIncomingDateEditWarning: (number) => `You are changing the Incoming Date for ${number} selected Artworks`,
    multiPackagingEditWarning: (number) => `You are changing the Packaging for ${number} selected Artworks`,
    multiPackagingInstructionsEditWarning: (number) =>
      `You are changing the Packaging Instructions for ${number} selected Artworks`,
    multiNotesEditWarning: (number) => `You are changing the Notes for ${number} selected Artworks`,
    collapseToArtworks: (number) => `Collapse to ${number} artworks`,
    showAllArtworks: (number) => `Show all ${number} artworks`,
    confirmDeleteArtworks: (number) => `Are you sure you want to delete ${number} Artwork${number > 1 ? "s" : ""} ?`,
    numFreeTrial: (number) => `${number}-day free trial`,
    dateStartingToPay: (date) => `Starting ${date.date()} ${date.format("MMMM")}`,
    expiredCreditCard: (lastFourDigit) =>
      `The credit card ending in ${lastFourDigit} has expired. Please fill in your new credit card details to continue using your account.`,
    changeBillingPeriod: (period) => `You’re switching to ${period} billing.`,
    changeBillingPeriodMessage: (currentPeriodEnd = "", price = "", billing_period = "", lastFourNumbers = "") =>
      `From ${moment(currentPeriodEnd * 1000).format("ddd MMM DD YYYY")}, we’ll charge €${price} ${
        billing_period === "monthly" ? "each month" : "each year"
      } to the credit card ending in ${lastFourNumbers}.`,
    popupHeaderForDelete: (name) => `Are you sure you want to delete this ${name}?`,
    subscriptionCharge: (amount, billing_period) =>
      `From ${moment().format("dd MMM YYYY")}, we'll charge ${billing_period} €${amount}`,
  },
});

export const InputLabels = new LocalizedStrings({
  en: {
    artist: "Artist Name",
    artist_text: "Artist",
    title: "Title",
    year: "Year",
    technique: "Technique",
    dimensions: "Dimensions",
    dimensionsVariable: "Variable dimensions",
    height: "Height",
    width: "Width",
    depth: "Depth",
    diameter: "Diameter",
    location: "Location",
    position: "Position",
    price: "Price",
    source: "Source",
    seriesTitle: "Series Title",
    isFramed: "Framed",
    typeOfFrame: "Type of Frame",
    type: "Type",
    edition: "Edition",
    currentOwner: "Current Owner",
    insuranceValue: "Insurance Value",
    productionCosts: "Production Costs",
    framingCosts: "Framing Costs",
    photoCopyrightOwner: "Photo Copyright",
    incomingDate: "Incoming Date",
    installationInstructions: "Installation Instructions",
    workDescription: "Work Description",
    description: "Description",
    notes: "Notes",
    sortBy: "Sort by",
    organisationName: "Organisation Name",
    recipientEmail: "Recipient Email",
    message: "Message",
    sharePrice: "Share Price",
    pickupAddress: "Pickup Address",
    destinationAddress: "Destination Address",
    id: "ID",
    email: "E-mail Address",
    emailText: "Email",
    password: "Password",
    fullName: "Full Name",
    amArtist: "I am an artist",
    organisation: "Name of Organisation (ie. Gallery)",
    organisationType: "Type of Organisation",
    gallery: "Gallery",
    gallerist: "Gallerist",
    collector: "Collector",
    curator: "Curator",
    technician: "Technician",
    artistType:"Artist",
    journalist:"Journalist",
    museum:"Museum",
    auction:"Auction house",
    writer:"Writer",
    historian:"Art historian",
    transport:"Transport",
    insurance:"Insurance",
    fair:"Fair",
    institution:"Institution",
    vip:"VIP",
    sponsor:"Sponsor",
    hotel:"Hotel",
    catering:"Catering",

    artProfessional: "Art Professional",
    artInstitution: "Art Institution",
    other: "Other",
    creditCardDetails: "Credit Card Details",
    resetCode: "Reset Code",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    collection: "Collection",
    portfolio: "Portfolio",
    workList: "Work List",
    transportList: "Transport List",
    insuranceList: "Insurance List",
    priceList: "Price List",
    dataSheet: "Data Sheet",
    invoice: "Invoice",
    consignmentAgreement: "Consignment Agreement",
    certificate: "Certificate",
    conditionReport: "Condition Report",
    installationManual: "Installation Manual",
    handoff: "Handoff Document",
    originAddress: "Coming From",
    targetAddress: "Deliver To",
    storageLabel: "Storage Label",
    wallLabel: "Wall Label",
    csv: "CSV Document",
    discount: "Discount",
    vat: "VAT",
    bankDetails: "Bank Details",
    paymentConditions: "Payment Conditions",
    remarks: "Remarks",
    additionalNotes: "Additional Notes",
    labelFormat: "Label Format",
    regular: "Regular",
    slim: "Slim",
    small: "Small",
    offset: "Offset",
    guidelines: "Print Guidelines",
    loanDuration: "Loan Duration",
    borrowerName: "Borrower Name",
    lenderName: "Lender Name",
    address: "Address",
    contactPerson: "Contact Person",
    phoneNumber: "Phone Number",
    phone: "Phone",
    duration: "Duration",
    printCredits: "Print Credits",
    photoCredits: "Photo Credits",
    returnAddress: "Return Address",
    insuranceAgreement: "Insurance Agreement",
    loanAgreement: "Loan Agreement",
    cost: "Cost",
    invoiceAddress: "Invoice Address",
    packagingType: "Type of Packaging",
    openingOrder: "Open in Order",
    status: "Status",
    vatNumber: "VAT Number",
    uploadGalleryLogo: "Upload Gallery Logo",
    website: "Website",
    firstName: "First Name",
    lastName: "Last Name",
    organisationContact: "Organisation",
    addressLine1: "Address Line",
    addressLine2: "Address Line 2",
    city: "City",
    region: "Region",
    postCode: "Postal Code",
    country: "Country",
    currency: "Currency",
    text: "Text",
    thin: "Thin",
    percentageArtistSplit: "Artist Sale Split",
    percentageGallerySplit: "Gallery Sale Split",
    instagramUrl: "Instagram URL",
    state: "State",
  },
});

export const InputValues = new LocalizedStrings({
  en: {
    available: "Available",
    onsite: "On Site",
    reserved: "Reserved",
    inProduction: "In Production",
    inTransit: "In Transit",
    sold: "Sold",
    onLoan: "On Loan",
  },
});

export const InputPlaceholders = new LocalizedStrings({
  en: {
    searchArtworks: "Search Artworks",
    optional: "Optional",
    email: "E-mail address",
    emailText: "Email",
    resetCode: "Reset Code",
    password: "Password",
    newPassword: "New password",
    confirmPassword: "Confirm New Password",
    organisation: "Name Of Organisation",
    name: "Name",
    artistName: "Artist Name",
    address: "Address",
    apt: "Apt [Opt]",
    city: "City",
    country: "Country",
    postalCode: "Postal Code",
    creditCardNumber: "Credit Card Number",
  },
});
