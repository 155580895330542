import {
  INITIALISE_SYNC_SYSTEM,
  SYNC_STARTED,
  SYNC_SUCCESS,
  SYNC_FAILURE,
  START_BACKGROUND_SYNC,
  STOP_BACKGROUND_SYNC,
  INCREASE_SYNC_COUNT
} from "./types";

export const initialiseSyncSystem = startSync => {
  return { type: INITIALISE_SYNC_SYSTEM };
};

export const syncStarted = startSync => {
  return { type: SYNC_STARTED, payload: { startSync } };
};

export const syncSuccess = (lastSyncSuccess, nextSync) => {
  return { type: SYNC_SUCCESS, payload: { lastSyncSuccess, nextSync } };
};

export const syncFailure = lastSyncFailure => {
  return { type: SYNC_FAILURE, payload: { lastSyncFailure } };
};

export const startBackgroundSync = () => {
  return { type: START_BACKGROUND_SYNC, payload: {} };
};

export const stopBackgroundSync = () => {
  return { type: STOP_BACKGROUND_SYNC, payload: {} };
};

export const increaseSyncCount = () => {
  return { type: INCREASE_SYNC_COUNT, payload: {} };
};
