import React from "react";
import styled from "styled-components";
import { Headings, UiLabels, ButtonLabels } from "../../localisation";

import { Box, Flex, Text } from "../fundamentals";
import { Button } from "../elements";

const StyledArtworkDetailsHeader = styled(Flex)`
  position: sticky;
  position: -webkit-sticky;
  justify-content: space-between;
  align-items: center;
  top: -1px;
  padding-top: 1px;
  z-index: 1;
  height: 56px;
  pointer-events: none !important;
  ${(props) => `
    background-color: ${props.sticky ? "white" : "transparent"};
    & > * {
      transition: all ${props.sticky ? 0.3 : 0}s;
    }
  `}
`;

const ArtworkDetailsHeader = ({
  artwork,
  sticky,
  customRef,
  sidebarWidth,
  renderStatusSelector,
  isFirstArtwork,
  isLastArtwork,
  showNavigationButtons,
  handleOnNavigateToArtworkCallback,
  isMultiEditMode,
  ...props
}) => {
  return (
    <StyledArtworkDetailsHeader ref={customRef} boxShadow={sticky ? "small" : null} sticky={sticky} {...props}>
      <Flex
        flex="1"
        color="grey.80"
        fontSize={sticky ? "body" : "heading2"}
        lineHeight={sticky ? "body" : "heading2"}
        fontFamily="bodyDmSans"
        pt={["xs", "s"]}
        pb={["xs", "s"]}
        pointerEvents="all"
        overflow="hidden"
        minWidth="520px"
      >
        <Text>{Headings.artworks}</Text>
        <Text ml="xxs" userSelect="none">
          /
        </Text>
        <Text ml="xxs" whiteSpace="nowrap">
          {artwork.artist}
        </Text>
        <Text ml="xxs" userSelect="none">
          /
        </Text>
        <Text
          flex="1"
          color="black"
          ml="xxs"
          whiteSpace="nowrap"
          overflow="hidden"
          minWidth="0"
          mr={!showNavigationButtons ? "m" : "none"}
        >
          {isMultiEditMode ? `Title ${UiLabels.multiple}` : artwork.artworkValues.title}
        </Text>
        {showNavigationButtons && (
          <Flex mr="xxxs">
            <Button
              disabled={isFirstArtwork}
              alignSelf="center"
              buttonStyle="text"
              size="small"
              icon="ArrowLeft"
              onClick={() => handleOnNavigateToArtworkCallback("prev")}
              pr="xxs"
            >
              {ButtonLabels.prev}
            </Button>
            <Button
              disabled={isLastArtwork}
              alignSelf="center"
              flip={true}
              buttonStyle="text"
              size="small"
              icon="ArrowRight"
              onClick={() => handleOnNavigateToArtworkCallback("next")}
              pl="xxs"
            >
              {ButtonLabels.next}
            </Button>
          </Flex>
        )}
      </Flex>
      <Box opacity={sticky ? 1 : 0} minWidth={sticky ? "233px" : sidebarWidth - 2} pr="xl">
        <Flex pointerEvents="all" justifyContent="space-between">
          {renderStatusSelector()}
          <Text size="body" whiteSpace="nowrap" ml="m">
            {isMultiEditMode ? `ID ${UiLabels.multiple}` : `ID \u0023${artwork.archiveArtworkNumber}`}
          </Text>
        </Flex>
      </Box>
    </StyledArtworkDetailsHeader>
  );
};

export { ArtworkDetailsHeader };
