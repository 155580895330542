import LocalizedStrings from "react-localization";

export const ApiErrors = new LocalizedStrings({
  en: {
    generic: "Something went wrong. Please try again later",
    noEmailExists: "No account for this email address. Please sign up",
    emailExists: "Account with email already exists",
    noPasswordMatch: "Invalid password",
    invalidPasswordResetCode: "Reset code invalid",
    wrongCurrentPassword: "Current password incorrect",
    failedChangingPassword: "Could not change your password",
    failedToGetCardDetails: "Could not get card details",
  },
});

export const FormErrors = new LocalizedStrings({
  en: {
    checkFormErrors: "The form has highlighted errors you need to fix",
  },
});

export const InputErrors = new LocalizedStrings({
  en: {
    noEmail: "Enter e-mail address",
    invalidEmail: "Enter valid e-mail address",
    noPassword: "Enter your password",
    noConfirmPassword: "Enter your confirm password",
    insecurePassword: "Password must be at least 9 characters",
    confirmPasswordMatch: "Confirm password should match with new password",
    noResetCode: "Enter reset code from email",
    noName: "Enter your full name",
    noRole: "Select your role",
    noOrganisation: "Enter your organisation name",
    noTitle: "Add a title",
    noArtist: "Add an artist",
    notUserArtist: "Artworks in production must be yours",
    noWidth: "Enter width",
    noHeight: "Enter height",
    noPlan: "Please select a plan",
    noAddress: "Please enter address",
    noCity: "Please enter city",
    noCountry: "Please select country",
    noPostalCode: "Please enter postal code",
    noCardNumber: "Please enter card number",
    noCardExpiryDate: "Please enter card expiry date",
    noCardCvc: "Please enter card cvc",
    noCurrentPassword: "Enter your current password",
    noNewPassword: "Enter your new password",
    numberYear: "Year must be a number",
    noCollection: "Select a collection",
    genericNoFieldPrefix: "Add a",
    noWidthError: "Enter width",
    noHeightError: "Enter height",
    percetageNot100: "Percentages must add up to 100%",
    noContactGeneral: "Please provide either a First Name, Last Name or Organisation.",
    wrongUrl: "Please type a URL that begins with https:// or http:// or www.",
  },
});

export const Info = new LocalizedStrings({
  en: {
    passwordLength: "Password must be at least 9 characters long.",
    collectorDesc: "You are an art lover and/or you buy and collect art",
    artistDesc: "You make art",
    galleristDesc: "You own, manage or work in a gallery",
    artProfessionalDesc: "You are a curator, art advisor etc",
    artInstitutionDesc: "You organize exhibitions",
    organisationInfo:
      "Optional. If not filled in, your name will be displayed on exported documents. You can change these details later in your account settings.",
    artistInfo:
      "This will be the default artist name for any artworks you add. You can change this later in your account settings.",
    artInfo:
      "This will be displayed on exported documents. You can change these details later in your account settings.",
  },
});

export const Warnings = new LocalizedStrings({
  en: {
    cancelSubscription: "Are you sure you want to cancel your subscription?",
    cancelSubscriptionDesc: "You can continue to use your account until the end of your current billing period. ",
    deleteAccount: "Deleting your account results in all data lost forever.",
    missingSelectedArtworkPrices: "Some artworks selected do not have a price",
    logoutAlertTitle: "Confirm sign out",
    logoutAlertDescription: "You have unsaved changes that will be lost. Connect to internet to save them.",
    popupWarning: "This action cannot be undone.",
  },
});

export const Notifications = new LocalizedStrings({
  en: {
    generic: { text: "Something went wrong. Please try again later" },
    accountCreatedSuccessfully: { text: "Account created successfully" },
    syncActionOffline: { text: "This action will be synced when back online" },
    exportSuccess: { text: "Export selected artworks successfully" },
    exportFailed: { text: "Could not export selected artworks" },
    createViewingRoomFailed: { text: "Could not create viewing room with selected artworks" },
    offlineQueued: { text: "Something went wrong. Will try again automatically when connection is re-established" },
    passwordResetCodeSent: { text: "Your password reset code has been sent to your email" },
    passwordChangedSuccessfully: { text: "Password changed successfully" },
    passwordResetFailed: { text: "Something went wrong. Could not update your password" },
    accountDetailsUpdateSuccess: { text: "Account details updated successfully" },
    accountDetailsUpdateFailed: { text: "Failed to update account details" },
    organisationDetailsUpdateSuccess: { text: "Your organisation details have updated successfully" },
    organisationDetailsUpdateFailed: { text: "Failed to update organisation details" },
    organisationLogoUploadFailed: { text: "Failed to upload organisation logo" },
    subscriptionSuccessful: { text: "Signup successful! Your subsciption has activated" },
    creditCardUpdateSuccessful: { text: "Credit card updated successfully!" },
    subscriptionFailed: { text: "Payment failed. Please try again or email us at contact@simplify.art for support" },
    creditCardUpdateFailed: {
      text: "Could not update your credit card. Please try again or email us at contact@simplify.art for support",
    },
    subscriptionCancelSuccess: {
      text: "Your subscription has been cancelled. You will continue to have access until your current billing period ends",
    },
    subscriptionCancelFailed: {
      text: "Could not cancel your subscription. Try again or email us on contact@simplify.art for support",
    },
    subscriptionChangeSuccess: {
      text: "Your subscription has been changed.",
    },
    subscriptionChangeFailed: {
      text: "Could not change your subscription. Try again or email us on contact@simplify.art for support",
    },
    subscriptionRestartSuccess: {
      text: "Your subscription has been restarted.",
    },
    subscriptionRestartFailed: {
      text: "Could not restart your subscription. Try again or email us on contact@simplify.art for support",
    },
    changeBillingCardFailed: {
      text: "Could not change billing card. Try again or email us on contact@simplify.art for support",
    },
    changeBillingCardSuccess: {
      text: "Billing card changed successful",
    },
    paymentMethodInvalid: {
      text: "Failed proccessing payment method. Please try a different card or email contact@simplify.art for support",
    },
    failedDropboxUpload: { text: "Could not add dropbox image. Please try again" },
    shareWhenSynced: { text: "Artwork not completely synced. It will be shared when uploads are finished" },
    shareSuccess: { text: "Artwork shared successfully" },
    shareFailure: { text: "Artwork shared failed" },
    saveShareArtworkSuccess: { text: "Saving shared artwork successfully" },
    saveShareArtworkFailure: { text: "Saving shared artwork failed" },
    saveAndCopyArtworkSuccess: { text: "Save and Copy artwork successfully" },
    saveAndCopyArtworkFailure: { text: "Save and Copy artwork failed" },
    saveAndNewArtworkSuccess: { text: "Save and New artwork successfully" },
    saveAndNewArtworkFailure: { text: "Save and New artwork failed" },
    multiEditArtworksSuccess: { text: "Multi Edit artworks successfully" },
    multiEditArtworksFailure: { text: "Multi Edit artworks failed" },
  },
});

export const DynamicNotifications = new LocalizedStrings({
  en: {
    artworkSyncMaxRetry: (title) => ({ text: `${title} sync failed. Click upload on the artwork to sync manually` }),
  },
});

export const MaxRetryNotifications = new LocalizedStrings({
  en: {},
});
