import LocalizedStrings from "react-localization";

export const Instructions = new LocalizedStrings({
  en: {
    addImages: "Drag images to reorder them. The top image is used as the artwork’s cover.",
    addFirstImage: "Add your first image",
    forgotPassword:
      "Enter the email address you used to signup with and we will send you a link to reset your password.",
    forgotPasswordText: "Enter your email address and we will send you a reset code",
    changePassword: "Enter the code from the email we just sent and create your new password",
    listType: "Select type of document you want to create",
    viewingRoomPassword: "Enter viewing room password",
    noAccount: "Don't have an account?",
    accountExists: "Already simplifying things?",
    needHelp: "Need help?",
    noCode: "Didn’t receive a code?",
    acceptTerms: "By creating an account you agree to",
    acceptTermsText: "By clicking the button above, I agree to simplify.art's ",
    unsubscribeReason: "We are sad to see you go! What is your reason for leaving simplify.art?",
    downloadMobileApps: "For the best possible experience, download our companion mobile app from your app store",
    exportsTypeSelect: "Select what type of document you want to create",
    installationManual: "Create a PDF document with instructions and packaging information",
  },
});

export const Prompts = new LocalizedStrings({
  en: {
    unsyncedLogout: (numUnsyncedActions) =>
      `You have ${numUnsyncedActions} unsynced items in your archive. All unsynced data will be lost`,
  },
});

export const Messages = new LocalizedStrings({
  en: {
    welcomeRedirectText: "You are now being redirected to the simplify.art web app",
    welcomeNotRedirectedText: "If you are not redirected automaticaly, please click ",
    welcome: "Welcome to simplify.art. Your new account is all set up and ready.",
    trialMessage: (trialLength) =>
      `You will not be charged for the first ${trialLength} day${trialLength > 1 ? "s" : ""} of your subscription`,
    paymentMessage: "You can cancel anytime",
    certificateText: "The undersigned ensures herewith the authenticity of the artwork shown below.",
    noArtWorks: "You have no artworks",
    noContacts: "You have no contacts",
    addArtWorks: "No need to worry! Simply click the add artwork button below to get started.",
    addContacts: "No need to worry! Simply click the add contact button below to get started.",
    noArtWorksFound: "No artworks found",
    noContactFound: "No Contacts found",
    upComingListFeature:
      "With Lists you can save a group of artworks to edit and export later. If you have any input, suggestions or requests, feel free to get in touch!",
    comingSoon: "Coming soon!",
    upComingExhibitionFeature:
      "With Exhibitions, you can archive images and information about exhibitions and link them to artworks and artists. If you have any input, suggestions or requests, feel free to get in touch!",
    upComingEventFeature:
      "With Events, you can keep track of invites and RSVPs and add them to your calendar. If you have any input, suggestions or requests, feel free to get in touch!",
    upComingArtistFeature:
      "With Artists, you can record their CVs and publications. If you have any input, suggestions or requests, feel free to get in touch!",
    emptySearch: (query) => `Your search “${query}” did not match any artworks. Please try again.`,
    emptySearchContact: (query) => `Your search “${query}” did not match any contacts. Please try again.`,
  },
});

export const FeatureDescriptions = new LocalizedStrings({
  en: {
    waltersCubeExhibitions:
      "Creating beautiful 3D online exhibitions in Walters Cube is in BETA!\n\nIt will be free to use until the summer. We encourage you to try it out and let us know what you think.",
    whiteLabeledExports:
      "You can upload your custom logo in Organisation Settings and it will appear in all your exports!",
  },
});
