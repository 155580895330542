import _ from "lodash";
import { createSelector } from "reselect";
import { selectArtworkId } from "./archiveSelectors";

export const selectActionQueue = (state) => state.queue.actionQueue;

export const selectDelayActionQueue = (state) => state.queue.delayQueue;

export const selectFailedActionQueue = (state) => state.queue.failedActionQueue;

export const selectExecutingActions = (state) => state.queue.executingActions.byId;

export const selectIsArtworkSynced = createSelector(
  selectFailedActionQueue,
  selectArtworkId,
  (failedActionQueue, artworkId) => {
    const isSynced = _.every(failedActionQueue, (failedAction) => {
      return failedAction.itemId !== artworkId;
    });

    return isSynced;
  }
);
