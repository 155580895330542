import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  selectUser,
  selectCollections,
  selectSelectedCollectionId,
  selectFailedActionQueue,
} from "../../store/selectors";
import {
  changeSelectedCollection,
  clearAllSelectedArtworks,
  signOut,
  seenAllShareRecords,
  openModal,
} from "../../store/actions";
import { clickedInsideNode } from "../../utils";

import { ButtonLabels } from "../../localisation";

import { Box, Flex, Image, Separator, Link } from "../fundamentals";
import { Button } from "../elements";
import { Dropdown, DropdownItem, DropdownContainer, SharingDropdown } from ".";

const StyledNavigationBar = styled(Flex)`
  position: relative;
  justify-content: space-between;
  align-items: center;
`;

const NavigationBar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const collections = _.sortBy(useSelector(selectCollections), ["type", "name"]);
  const selectedCollectionId = useSelector(selectSelectedCollectionId);
  const failedActionQueue = useSelector(selectFailedActionQueue);

  const handleAddArtworkButtonClick = () => {
    dispatch(clearAllSelectedArtworks());
    history.push("/artworks/add");
  };

  const handleSelectCollectionButtonClick = (newSelectedCollection) => {
    dispatch(changeSelectedCollection(newSelectedCollection.id));
    if (location.pathname !== "/artworks") history.push("/artworks");
  };

  const handleSignOutButtonClick = () => {
    if (failedActionQueue.length > 0) {
      dispatch(openModal("confirm_signout_modal"));
    } else {
      dispatch(signOut());
    }
  };

  const handleSettingButtonClick = () => {
    dispatch(clearAllSelectedArtworks());
    history.push("/settings");
  };

  const handleShareDropdownClick = () => {
    dispatch(seenAllShareRecords());
  };

  return (
    <StyledNavigationBar pl="l" height={["64px", "80px"]} userSelect="none" {...props}>
      <Flex flexShrink={0}>
        <a href={process.env.REACT_APP_ENV === "production" ? "https://www.simplify.art?redirect=no" : "/"}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/brand-identity/logo-no-text.svg`}
            alt="logo"
            height="35px"
            width="25px"
          />
        </a>
        {_.map(collections, (collection) => (
          <NavigationItem
            key={collection.id}
            label={collection.name}
            active={selectedCollectionId === collection.id}
            onClick={() => handleSelectCollectionButtonClick(collection)}
          />
        ))}

        {/* <NavigationItem label={ButtonLabels.exhibitions} /> */}
        {/* <NavigationItem label={ButtonLabels.lists} /> */}
      </Flex>
      <Flex pr="s" flexShrink={0} alignItems="center">
        <Button buttonStyle="primary" size="medium" onClick={handleAddArtworkButtonClick}>
          {ButtonLabels.addArtwork}
        </Button>

        <Link href="https://www.simplify.art/help" target="_blank" mr="xxxs">
          <NavigationItem label={ButtonLabels.help} ml="s" pr="none" />
        </Link>

        <DropdownContainer
          buttonStyle="text"
          size="medium"
          icon="Bell"
          iconCursor="pointer"
          cursor="default"
          color="black"
          hoverColor="grey.80"
          allowInnerClicks={true}
          badge={user.session.unseenShareRecords}
          onClick={handleShareDropdownClick}
          component={Button}
          pl="l"
          mr="m"
          draggable
        >
          <Dropdown mt="xs" mr="mega" pb="none">
            <SharingDropdown />
          </Dropdown>
        </DropdownContainer>

        <NavigationItem id="profile_dropdown_button" icon="User" iconSize={20} pl="none">
          <Dropdown minWidth="220px" top="xxxl" right="l">
            <DropdownItem label={ButtonLabels.settings} onSelect={handleSettingButtonClick} />
            <Link href="mailto:contact@simplify.art">
              <DropdownItem label={ButtonLabels.contactUs} />
            </Link>
            <Separator />
            <DropdownItem label={ButtonLabels.signout} onSelect={handleSignOutButtonClick} />
          </Dropdown>
        </NavigationItem>
      </Flex>
    </StyledNavigationBar>
  );
};

const NavigationItem = ({ children, active, icon, iconSize, label, ...props }) => {
  const [open, setDropdownOpen] = useState();
  const ref = useRef();

  const handleClick = (e) => {
    setDropdownOpen(clickedInsideNode(e, ref) && !open);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [open]);

  return (
    <Box ref={ref} active={active}>
      <Button
        buttonStyle="text"
        size="medium"
        icon={icon}
        iconCursor="pointer"
        iconSize={iconSize}
        color={!active ? "black" : null}
        hoverColor={!active && "grey.80"}
        pl="s"
        pr="s"
        mb="xxxs"
        alignSelf="center"
        label={label}
        {...props}
      ></Button>
      {open && children}
    </Box>
  );
};
export { NavigationBar };
