import React from "react";
import styled from "styled-components";

import { Box } from ".";

const StyledFlex = styled(Box)`
  display: ${props => props.display || "flex"};
`;

const Flex = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledFlex ref={ref} {...props}>
      {children}
    </StyledFlex>
  );
});

export { Flex };
